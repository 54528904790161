import { deserialize } from 'deserialize-json-api'

import api from '@shared/services'

const UserSettingsApi = {
  all: (userId, config) =>
    api.get(`/users/${userId}/user_settings`, config).then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? []),

  byId: (userId, settingId, config) =>
    api
      .get(`/users/${userId}/user_settings/${settingId}`, config)
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? {}),

  update: (userId, settingId, data, config) =>
    api
      .patch(`/users/${userId}/user_settings/${settingId}`, data, config)
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? []),
}

export default UserSettingsApi
