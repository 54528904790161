import dayjs from 'dayjs'
import PropTypes from 'prop-types'

import { toTitleCase } from '@shared/utils'

import { Button, Chip, Divider, Stack, Typography } from '@mui-components'

import CBOSelect from './CBOSelect'
import DateRangeSelect from './DateRangeSelect'
import DateSelect from './DateSelect'
import MultiSelect from './MultiSelect'
import PharmacySelect from './PharmacySelect'
import ProviderSelect from './ProviderSelect'
import SingleSelect from './SingleSelect'
import StringSearch from './StringSearch'
import { FilterType, useChips } from './TableFilters.hooks'

TableFilters.propTypes = {
  schema: PropTypes.arrayOf(
    PropTypes.shape({
      /** Key of the filter */
      key: PropTypes.string.isRequired,
      /** Label of the filter */
      label: PropTypes.string,
      /** Type of the filter */
      type: PropTypes.oneOf(Object.values(FilterType)).isRequired,
      /**
       * 'defaultValue' is the initial value for the filter. If the current value of the filter matches this default value,
       * the filter cannot be removed. Useful for baseline filters that always apply.
       */
      defaultValue: PropTypes.any,
      /** Options to display in the menu (for select types) */
      options: PropTypes.arrayOf(
        PropTypes.shape({
          /** Label of the option */
          label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
          /** Value of the option */
          value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]).isRequired,
        })
      ),
      /** Placeholder for text-based filters */
      placeholder: PropTypes.string,
      /** Query parameters for searching filter */
      queryParams: PropTypes.object,
      /** Disable the filter */
      disabled: PropTypes.bool,
    })
  ),
  /** Current values of the filters */
  values: PropTypes.object,
  /** Callback when the filters change */
  onChange: PropTypes.func.isRequired,
  /** If `true`, the reset button is shown */
  showReset: PropTypes.bool,
  /** Callback when the reset button is clicked */
  onReset: PropTypes.func,
  /** Additional styles to apply to the root element */
  sx: PropTypes.object,
}

/**
 * TableFilters Component
 *
 * Renders a set of filters defined by a schema, displays applied filter chips, and
 * optionally provides a reset button. Each filter type is supported by a dedicated
 * input component.
 */
export default function TableFilters({ sx = {}, showReset = false, onReset, ...props }) {
  return (
    <Stack alignItems="center" direction="row" flexWrap="wrap" gap={1} sx={sx}>
      <Filters {...props} />
      <Chips {...props} />
      <Reset show={showReset} onReset={onReset} />
    </Stack>
  )
}

/**
 * Filters Component
 *
 * Maps over the filter schema and renders the appropriate input component for each filter type.
 */
export function Filters({ schema = [], values = {}, onChange }) {
  const renderFilterComponent = ({ type, key, label = key, placeholder, queryParams, disabled = false, options = [] }) => {
    const upperLabel = label.toUpperCase()

    // Helper functions for date formatting
    const formatDate = (d) => d.format('YYYY-MM-DD')
    const parseDate = (v) => (v ? dayjs(v, 'YYYY-MM-DD') : null)

    // Map each filter type to its corresponding component
    const filterRenderers = {
      [FilterType.MULTI_SELECT]: () => (
        <MultiSelect
          key={key}
          label={upperLabel}
          value={values[key]}
          options={options}
          disabled={disabled || options.length === 0}
          onChange={(selectedValues) => onChange({ [key]: selectedValues.length > 0 ? selectedValues : undefined })}
        />
      ),

      [FilterType.SELECT]: () => (
        <SingleSelect
          key={key}
          label={upperLabel}
          value={values[key]}
          options={options}
          disabled={disabled || options.length === 0}
          onChange={(val) => onChange({ [key]: val })}
        />
      ),

      [FilterType.DATE_RANGE]: () => (
        <DateRangeSelect
          key={key}
          label={upperLabel}
          value={(values[key] || []).map(parseDate)}
          onChange={(selectedRange = []) => onChange({ [key]: selectedRange.map(formatDate) })}
        />
      ),

      [FilterType.DATE]: () => (
        <DateSelect key={key} label={upperLabel} value={parseDate(values[key])} onChange={(val) => onChange({ [key]: formatDate(val) })} />
      ),

      [FilterType.PROVIDER]: () => (
        <ProviderSelect key={key} label={upperLabel} title={label} value={values[key]} onChange={(val) => onChange({ [key]: val })} />
      ),

      [FilterType.PHARMACY]: () => (
        <PharmacySelect key={key} label={upperLabel} title={label} value={values[key]} onChange={(val) => onChange({ [key]: val })} />
      ),

      [FilterType.CBO]: () => (
        <CBOSelect
          key={key}
          label={upperLabel}
          title={label}
          value={values[key]}
          onChange={(val) => onChange({ [key]: val })}
          queryParams={queryParams}
        />
      ),

      [FilterType.TEXT]: () => (
        <StringSearch
          key={key}
          label={upperLabel}
          title={label}
          placeholder={placeholder}
          value={values[key]}
          onChange={(val) => onChange({ [key]: val })}
        />
      ),
    }

    // Return the corresponding component if exists, otherwise null or a warning
    return filterRenderers[type] ? filterRenderers[type]() : null
  }

  return schema.map(renderFilterComponent).filter(Boolean)
}

/**
 * Chips Component
 *
 * Renders chips for each currently applied filter. Clicking or deleting a chip removes that filter.
 */
export function Chips({ schema = [], values = {}, onChange }) {
  const chips = useChips({ schema, values, onChange })

  if (chips.length === 0) {
    return <Typography color="text.secondary">No filters applied</Typography>
  }

  return chips
    .filter(Boolean)
    .map(({ key, value, label, onDelete, disabled = false, textTransform = 'none' }) => (
      <Chip
        key={`${key}-${value}`}
        clickable
        label={`${label || toTitleCase(key)} = ${value}`}
        size="small"
        onDelete={onDelete}
        onClick={onDelete}
        disabled={disabled}
        sx={{ textTransform }}
        data-testid={`filter-chip-${label || toTitleCase(key)}-${value}`}
      />
    ))
}

/**
 * Reset Component
 *
 * If `show` is true and an `onReset` function is provided,
 * renders a reset button that clears all filters.
 */
export function Reset({ show, onReset }) {
  if (!show || !onReset) return null

  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <Divider orientation="vertical" sx={{ height: 26 }} />
      <Button size="small" color="secondary" sx={{ textTransform: 'none' }} onClick={onReset} data-testid="clear-btn">
        clear all
      </Button>
    </Stack>
  )
}
