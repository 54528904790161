import { useEffect } from 'react'
import { useEventListener, useLocalStorage } from 'usehooks-ts'

import { Logger } from '@shared/utils'

const LIMIT = 50
const log = Logger('usePagesHistory')

const cleanUp = () => localStorage.removeItem('pages-history-v1')

export const PageHistoryType = {
  Patient: 'patient',
  Lab: 'lab',
  Rx: 'rx',
  Encounter: 'encounter',
}

// Free up space by removing the oldest history items
const freeUpSpaceForPagesHistory = (setHistory) => {
  try {
    setHistory((prev) => {
      if (prev.length === 0) return prev
      // Remove the oldest item (last in the array)
      const newHistory = prev.slice(0, -1)
      log.warn('Removing oldest history item to free up space')
      return newHistory
    })
    return true
  } catch (e) {
    log.error('Failed to free up space for pages history:', e)
    return false
  }
}

export const getPageHistoryId = (type, id) => `${type}-${id}`

export default function usePagesHistory() {
  const [history, setHistory] = useLocalStorage('pages-history-v2', [])

  const add = (type, data, parentId) => {
    const attemptToAdd = () => {
      try {
        setHistory((history) => {
          const id = getPageHistoryId(type, data.id)
          const createdAt = new Date().toISOString()

          const item = { id, createdAt, type, parentId, data }
          const updatedHistory = [item, ...history.filter((item) => item.id !== id)]

          if (updatedHistory.length > LIMIT) updatedHistory.pop()

          return updatedHistory
        })
        return true
      } catch (e) {
        if (e.name === 'QuotaExceededError') {
          log.warn('LocalStorage quota exceeded while adding to pages history')
          const freed = freeUpSpaceForPagesHistory(setHistory)
          if (freed) {
            log.warn('Retrying to add to pages history after freeing up space')
            return attemptToAdd()
          } else {
            log.warn('Could not free up space for pages history')
          }
        } else {
          log.error('Error adding to pages history:', e)
        }
        return false
      }
    }

    attemptToAdd()
  }

  const clear = () => setHistory([])

  return { history, add, clear }
}

export function usePagesHistoryListener(type, data, parentId) {
  const { add } = usePagesHistory()

  useEffect(() => {
    if (type && data) {
      log.debug('Adding to pages history via useEffect', { type, data, parentId })
      add(type, data, parentId)
    }
  }, [data, parentId, type]) // eslint-disable-line react-hooks/exhaustive-deps

  // Add item to the history when returns back to the page
  useEventListener('visibilitychange', () => {
    if (!document.hidden) {
      log.debug('Adding to pages history via visibilitychange', { type, data, parentId })
      add(type, data, parentId)
    }
  })
}

cleanUp()
