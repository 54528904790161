import { useEffect, useState } from 'react'

export default function useEffectState(initialState) {
  const [state, setState] = useState(initialState)

  useEffect(() => {
    setState(initialState)
  }, [initialState])

  return [state, setState]
}
