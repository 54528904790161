import { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'

import { useQueryEvents } from '@shared/hooks/src/useQueryEvents'
import { getTestId } from '@shared/utils'

import { usePharmacy } from '@pages/Pharmacies/Pharmacy/Pharmacy.hooks'
import { CloseOutlinedIcon, SearchOutlinedIcon } from '@icons'
import { Button, Skeleton, Typography } from '@mui-components'
import SelectPharmacy from '@components/SelectPharmacy'

import { FilterPopper } from '../TableFilters.utils'

PharmacySelect.propTypes = {
  /** Label of the button */
  label: PropTypes.string.isRequired,

  /** Title of the popper */
  title: PropTypes.string,

  /** Callback fired when the value changes */
  onChange: PropTypes.func.isRequired,

  /** Dayjs value */
  value: PropTypes.number,
}

/**
 * Button opens a popper to select or manually search a pharmacy
 *
 * @example
 * <PharmacySelect label="Pharmacy" value={value} onChange={handleChange} />
 */
export default function PharmacySelect({ label, title, onChange, value, ...other }) {
  const anchorRef = useRef(null)

  const [open, setOpen] = useState(false)
  const [pharmacy, setPharmacy] = useState(null)

  const testId = getTestId(other, `pharmacy-select-${label}`)

  // Reset the pharmacy if the initialValue changes to empty
  useEffect(() => {
    if (!value && pharmacy) {
      setPharmacy(null)
    }
  }, [value, pharmacy])

  const query = usePharmacy(value, { enabled: Boolean(value) && !pharmacy })

  useQueryEvents(query, {
    onSuccess: (pharmacy) => setPharmacy(pharmacy),
  })

  const handleToggle = () => setOpen((o) => !o)

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) return
    setOpen(false)
  }

  const handleSelect = (selectedPharmacy) => {
    onChange(selectedPharmacy?.id)
    setPharmacy(selectedPharmacy)
    setOpen(false)
  }

  return (
    <>
      <Button
        ref={anchorRef}
        color="inherit"
        endIcon={value ? <CloseOutlinedIcon /> : <SearchOutlinedIcon />}
        onClick={value ? () => onChange(undefined) : handleToggle}
        data-testid={`${testId}-button`}
        {...other}
      >
        {label}
        {(pharmacy || query.isFetching) && (
          <Typography component="span" color="primary.main" fontWeight="bold" textTransform="none" noWrap>
            {pharmacy ? `: ${pharmacy.name}` : <Skeleton width={80} sx={{ ml: 1 }} />}
          </Typography>
        )}
      </Button>
      <FilterPopper title={title} open={open} onClose={handleClose} anchorEl={anchorRef.current}>
        <SelectPharmacy
          openOnFocus
          placeholder="Pharmacy"
          disabled={query.isFetching}
          loading={query.isFetching}
          value={pharmacy}
          onChange={handleSelect}
          inputProps={{ autoFocus: true }}
          data-testid={testId}
        />
      </FilterPopper>
    </>
  )
}
