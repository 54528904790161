import sortBy from 'lodash/sortBy'

import useQuery from '@shared/hooks/src/useQuery'
import API from '@shared/services/src/API'
import { QK } from '@shared/utils'

export default function usePharmacies(query, options = {}) {
  return useQuery({
    queryKey: QK.pharmacies.list(query),
    queryFn: () => API.pharmacies.list(query),
    select: (pharmacies) => sortBy(pharmacies, 'name'),
    ...options,
  })
}
