import { useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'

import usePubNub from '@shared/hooks/src/usePubNub'
import { useMe } from '@shared/providers/src/MeProvider'
import { queryClient } from '@shared/providers/src/QueryClientProvider'
import { QK } from '@shared/utils'

import { CardActionArea, Snackbar, Typography } from '@mui-components'

import { styles, Title } from '../Notifications.utils'

/**
 * Handle the display of an ROI medical record being generated in MA Task
 */
export default function ROIMedicalRecordGenerated() {
  const me = useMe()
  const [open, setOpen] = useState(false)
  const [taskId, setTaskId] = useState(undefined)

  const handleClose = () => setOpen(false)

  usePubNub(`user_${me.id}`, ({ action, attributes }) => {
    if (action !== 'roi_medical_record_generated') return
    const taskId = attributes.task_id

    queryClient.invalidateQueries({ queryKey: QK.tasks.id(taskId).documents.lists })
    setTaskId(taskId)
    setOpen(true)
  })

  return (
    <Snackbar
      open={open}
      onClose={(event, reason) => {
        if (reason === 'escapeKeyDown') handleClose()
      }}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      <CardActionArea component={RouterLink} to={`/app?task=${taskId}`} onClick={handleClose} sx={styles.container}>
        <Title label="ROI Medical Record completed generation" onClose={handleClose} />
        <Typography>Please press here to open the task and review the PDF</Typography>
      </CardActionArea>
    </Snackbar>
  )
}
