import { useInfiniteQuery } from '@tanstack/react-query'

import { useMe } from '@shared/providers/src/MeProvider'
import API from '@shared/services/src/API'
import { flatten, pageParam, QK } from '@shared/utils'

const LIMIT = 20

export function useMentions(params = {}, options = {}) {
  const me = useMe()
  const query = { ...params, order: 'desc', limit: LIMIT }
  return useInfiniteQuery({
    queryKey: QK.users.id(me.id).mentions.list(query),
    queryFn: ({ pageParam }) => API.users.id(me.id).mentions.list({ ...query, offset: pageParam * LIMIT }),
    select: flatten,
    initialPageParam: 0,
    getNextPageParam: pageParam(LIMIT),
    ...options,
  })
}
