import { useEffect, useRef, useState } from 'react'

import { DeleteOutlinedIcon } from '@icons'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Stack, TextField } from '@mui-components'

function SelectorDialog({ open, onClose, initialOptions = [''], onSubmit }) {
  const optionRefs = useRef([])
  const [selectorOptions, setSelectorOptions] = useState(initialOptions)

  useEffect(() => {
    setSelectorOptions(initialOptions)
  }, [initialOptions])

  const handleSelectorOptionChange = (index, value) => {
    const newOptions = [...selectorOptions]
    newOptions[index] = value
    setSelectorOptions(newOptions)
  }

  const handleAddOptionAtIndex = (index) => {
    const newOptions = [...selectorOptions]
    newOptions.splice(index + 1, 0, '')
    setSelectorOptions(newOptions)
    setTimeout(() => {
      if (optionRefs.current[index + 1]) {
        optionRefs.current[index + 1].focus()
      }
    }, 0)
  }

  const handleRemoveOptionAtIndex = (index) => {
    const newOptions = [...selectorOptions]
    newOptions.splice(index, 1)
    setSelectorOptions(newOptions)
    optionRefs.current.splice(index, 1)
  }

  const handleKeyDown = (e, index) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      handleAddOptionAtIndex(index)
    }
  }

  const handleSubmit = () => {
    const options = selectorOptions.filter((option) => option.trim() !== '')
    if (options.length > 0) {
      onSubmit(options)
    }
    onClose()
  }

  return (
    <Dialog fullWidth maxWidth="xs" open={open} onClose={onClose} scroll="body">
      <DialogTitle>Selector Options</DialogTitle>
      <DialogContent dividers>
        <Stack spacing={2}>
          {selectorOptions.map((option, i) => {
            const canBeRemoved = selectorOptions.length > 1
            return (
              <Stack key={i} direction="row" alignItems="center" spacing={1}>
                <TextField
                  autoFocus={i === 0}
                  fullWidth
                  label={`Option ${i + 1}`}
                  value={option}
                  onChange={(e) => handleSelectorOptionChange(i, e.target.value)}
                  onKeyDown={(e) => handleKeyDown(e, i)}
                  inputRef={(el) => (optionRefs.current[i] = el)}
                />
                {canBeRemoved && (
                  <IconButton onClick={() => handleRemoveOptionAtIndex(i)}>
                    <DeleteOutlinedIcon />
                  </IconButton>
                )}
              </Stack>
            )
          })}
          <Button onClick={() => handleAddOptionAtIndex(selectorOptions.length - 1)}>Add Another Option</Button>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSubmit} variant="contained" color="primary">
          Complete
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default SelectorDialog
