import useTheme from '@mui/styles/useTheme'

import { CheckCircleOutlinedIcon, CopyOutlinedIcon } from '@icons'
import CopyToClipboard from '@components/CopyToClipboard'

export default function CopyIconButton({ fn, sx = {} }) {
  const theme = useTheme()

  return (
    <CopyToClipboard>
      {({ copy, copied }) => {
        const Icon = copied ? CheckCircleOutlinedIcon : CopyOutlinedIcon
        return <Icon onClick={() => fn(copy)} style={{ fontSize: 18, cursor: 'pointer', color: theme.palette.text.secondary, ...sx }} />
      }}
    </CopyToClipboard>
  )
}
