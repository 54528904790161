import { useMutation } from '@tanstack/react-query'

import useQuery from '@shared/hooks/src/useQuery'
import { queryClient } from '@shared/providers/src/QueryClientProvider'
import API from '@shared/services/src/API'
import { extendCache, getErrorDetails, handleError, mapCache, QK } from '@shared/utils'

export function usePatientEncounterAvailabilities(patientId, encounterId, query, options = {}) {
  return useQuery({
    queryKey: QK.patients.id(patientId).encounters.id(encounterId).availabilities.list(query),
    queryFn: () => API.patients.id(patientId).encounters.id(encounterId).availabilities.list(query),
    ...options,
  })
}

export function useAppointmentCreate(patientId, encounterId) {
  return useMutation({
    mutationFn: (data) => API.patients.id(patientId).encounters.id(encounterId).appointments.scheduled.create(data),
    onSuccess: (appointment) => {
      queryClient.setQueryData(QK.patients.id(patientId).encounters.id(encounterId).details, (encounter) => ({
        ...encounter,
        canCreateScheduledAppointments: false,
        canCreateUnscheduledAppointments: false,
      }))

      queryClient.removeQueries({ queryKey: QK.patients.id(patientId).encounters.id(encounterId).availabilities.lists })

      queryClient.setQueriesData(
        { queryKey: QK.patients.id(patientId).encounters.id(encounterId).appointments.lists },
        extendCache((list) => [appointment, ...list], 5)
      )

      // Provider dashboard appointments require invalidation
      queryClient.invalidateQueries({ queryKey: QK.providers.id(appointment.provider.id).appointments.lists })
    },
    onError: (e) => {
      const message = getErrorDetails(e).join(', ')
      handleError(e, { message })
    },
  })
}

export function useAppointmentUpdate(patientId, encounterId, appointmentId) {
  return useMutation({
    mutationFn: (data) => API.patients.id(patientId).encounters.id(encounterId).appointments.scheduled.id(appointmentId).update(data),
    onSuccess: (appointment) => {
      queryClient.removeQueries({ queryKey: QK.patients.id(patientId).encounters.id(encounterId).availabilities.lists })

      queryClient.setQueryData(QK.appointments.id(appointmentId).details, (old) => ({ ...old, ...appointment }))

      // Encounter appointments
      queryClient.setQueriesData(
        { queryKey: QK.patients.id(patientId).encounters.id(encounterId).appointments.lists },
        mapCache((item) => (item.id === appointmentId ? { ...item, ...appointment } : item))
      )

      // Appointments page
      queryClient.setQueriesData(
        { queryKey: QK.appointments.lists },
        mapCache((item) => (item.id === appointmentId ? { ...item, ...appointment } : item))
      )

      // Scheduling Calendar
      queryClient.setQueriesData(
        { queryKey: QK.providers.id(appointment.provider.id).appointments.lists },
        mapCache((item) => (item.id === appointmentId ? { ...item, ...appointment } : item))
      )

      // Provider dashboard appointments require invalidation
      queryClient.invalidateQueries({ queryKey: QK.providers.id(appointment.provider.id).appointments.lists })
    },
    onError: (e) => {
      const message = getErrorDetails(e).join(', ')
      handleError(e, { message })
    },
  })
}
