import { forwardRef } from 'react'
import { EditorContent, useEditor } from '@tiptap/react'

import { clipboardTextParser } from '@shared/messaging/src/RichTextHelper'

import RichTextEditorToolbar from '@components/RichText/RichTextEditorToolbar'

const RichTextEditor = forwardRef(({ initialValue, extensions, selectorEnabled = false, dateEnabled = false, ...rest }, ref) => {
  const editor = useEditor({
    extensions,
    content: initialValue,
    autofocus: 'end',
    editorProps: { clipboardTextParser },
    ...rest,
  })

  ref.current = editor

  return (
    <>
      <RichTextEditorToolbar editor={editor} selectorEnabled={selectorEnabled} dateEnabled={dateEnabled} />
      <EditorContent editor={editor} />
    </>
  )
})

export default RichTextEditor
