import React, { createContext, useContext, useReducer, useState } from 'react'
import { useMutation } from '@tanstack/react-query'

import API from '@shared/services/src/API'
import { Logger } from '@shared/utils'

import { initialSettings, settingsReducer } from '../settings'

const StateContext = createContext(null)

const log = Logger('useVideoState.js')

export function VideoStateProvider({ children }) {
  const [error, setError] = useState(null)
  const [isFetching, setIsFetching] = useState(false)
  const [settings, dispatchSetting] = useReducer(settingsReducer, initialSettings)
  const [roomType, setRoomType] = useState()

  const handleError = (error) => {
    log.debug('Twilio Error boundary caught: ', error)
    setError(error)
  }

  const fetchToken = useMutation({
    mutationFn: ({ id, identity }) => API.appointments.id(id).videoToken({ identity }),
  })

  const contextValue = {
    error,
    setError: handleError,
    isFetching,
    settings,
    dispatchSetting,
    roomType,
    getToken: (appointmentId, identity) => fetchToken.mutateAsync({ id: appointmentId, identity }),
    updateRecordingRules: async (room_sid, rules) => {
      const endpoint = import.meta.env.VITE_TOKEN_ENDPOINT || '/recordingrules'

      return fetch(endpoint, {
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ room_sid, rules }),
        method: 'POST',
      })
        .then(async (res) => {
          const jsonResponse = await res.json()

          if (!res.ok) {
            const recordingError = new Error(jsonResponse.error?.message || 'There was an error updating recording rules')
            recordingError.code = jsonResponse.error?.code
            return Promise.reject(recordingError)
          }

          return jsonResponse
        })
        .catch(handleError)
    },
  }

  const getToken = (appointmentId, identity) => {
    setIsFetching(true)
    return contextValue
      .getToken(appointmentId, identity)
      .then((res) => {
        setRoomType(res.room_type)
        return res
      })
      .finally(() => setIsFetching(false))
  }

  const updateRecordingRules = (room_sid, rules) => {
    setIsFetching(true)
    return contextValue
      .updateRecordingRules(room_sid, rules)
      .then((res) => {
        setIsFetching(false)
        return res
      })
      .catch((err) => {
        handleError(err)
        setIsFetching(false)
        return Promise.reject(err)
      })
  }

  return <StateContext.Provider value={{ ...contextValue, getToken, updateRecordingRules }}>{children}</StateContext.Provider>
}

export default function useVideoState() {
  const context = useContext(StateContext)
  if (!context) {
    throw new Error('useVideoState must be used within the VideoStateProvider')
  }
  return context
}
