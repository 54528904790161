import useQuery from '@shared/hooks/src/useQuery'
import API from '@shared/services/src/API'
import { QK } from '@shared/utils'

export function useInsurance(patientId, insuranceId) {
  return useQuery({
    queryKey: QK.patients.id(patientId).insurances.id(insuranceId).details,
    queryFn: () => API.patients.id(patientId).insurances.id(insuranceId).details({ skipHandling: true }),
    enabled: Boolean(patientId) && Boolean(insuranceId),
  })
}
