import { deserialize } from 'deserialize-json-api'

import { imageNotAvailable, TodoType } from '@shared/utils'

import { Service } from './index'

const fileConfig = { skipDeserialization: true, timeout: Infinity, responseType: 'blob' }

const API = {
  platform: {
    businessHours: () => Service.get('/business_hours'),
    features: {
      list: () => Service.get('/feature_flags'),
      id: (featureId) => ({
        toggle: () => Service.patch(`/feature_flags/${featureId}/toggle`),
      }),
    },
    blackouts: {
      list: (query) => Service.get('/blackout_periods', query),
      create: (data) => Service.post('/blackout_periods', data),
      id: (blackoutId) => ({
        remove: () => Service.delete(`/blackout_periods/${blackoutId}`),
      }),
    },
    notifications: {
      list: (query) => Service.get('/platform_notifications', query),
      create: (data) => Service.post('/platform_notifications', data),
      id: (notificationId) => ({
        update: (data) => Service.put(`/platform_notifications/${notificationId}`, data),
      }),
    },
    states: {
      id: (name) => ({
        providers: {
          gaps: {
            availability: (query) => Service.get(`/states/${name}/providers/availability_gaps`, query).then(mapGaps),
          },
        },
      }),
    },
  },
  theme: {
    details: (query, config) => Service.get('/theme', query, config),
  },
  heartbeat: {
    check: (query, config = {}) =>
      Service.get(`${import.meta.env.VITE_API_URL.replace('/api', '')}/heartbeat`, query, {
        headers: { Accept: 'application/json' },
        ...config,
      }),
    keep: (data, config = {}) =>
      Service.post(`${import.meta.env.VITE_API_URL.replace('/api', '')}/heartbeat`, data, {
        headers: { Accept: 'application/json' },
        ...config,
      }),
  },
  auth: {
    forgotPassword: (data) => Service.post('/auth/forgot_password_email', data),
    login: {
      check: (query, config) => Service.get('/auth/login', query, config),
      execute: (data) => Service.post('/auth/login', data),
      dob: (data, config) => Service.post('/auth/dob_login', data, config),
      as: (userId) => Service.post(`/auth/login_as?user_id=${userId}`),
    },
    code: {
      login: (data, config) => Service.post('/auth/verification_code', data, config),
      resend: (data, config) => Service.put('/auth/verification_code/resend', data, config),
    },
  },
  admin: {
    metrics: () => Service.get('/admin_metrics'),
    cbos: {
      list: (query) => Service.get('/admin/cbos', query),
      id: (cboId) => ({
        updateStatus: (data) => Service.patch(`/admin/cbos/${cboId}/update_status`, data),
      }),
    },
    providers: {
      list: (query) => Service.get('/admin/providers', query),
      id: (providerId) => ({
        details: () => Service.get(`/admin/providers/${providerId}`),
      }),
    },
    systemUsers: {
      list: (query) => Service.get('/admin/system_users', query),
      create: (data) => Service.post('/admin/system_users', data),
      id: (userId) => ({
        details: () => Service.get(`/admin/system_users/${userId}`),
        update: (data) => Service.patch(`/admin/system_users/${userId}`, data),
      }),
    },
  },
  patients: {
    list: (query) => Service.get('/patients', query),
    create: (data) => Service.post('/patients', data),
    healthHistoryAlerts: (query) => Service.get('/patients_with_health_history_alerts', query),
    enrollment: {
      create: (data) => Service.post('/patients/enrollments', data),
      update: (data, config = {}) =>
        Service.patch('/patients/enrollments', data, config).then((res) => {
          if (data.enrollment_step === 'last') return res
          return deserialize(res, { transformKeys: 'camelCase' })?.data ?? []
        }),
    },
    reports: {
      main: (query) => Service.get('/patients/reports', query, fileConfig),
      cboAssignment: (query) => Service.get('/patients/reports/cbo_assignment', query, fileConfig),
      hiv: (query) => Service.get('/patients/reports/hiv', query, fileConfig),
      insurance: (query) => Service.get('/patients/reports/insurance', query, fileConfig),
      paDisposition: (variant, query) => Service.get(`/patients/reports/pa_disposition_${variant}`, query, fileConfig),
    },
    id: (patientId) => ({
      details: () => Service.get(`/patients/${patientId}`),
      internalConversation: (query) => Service.get(`/patients/${patientId}/internal_conversations`, query),
      update: (data) => Service.patch(`/patients/${patientId}`, data),
      accessible: () => Service.get(`/patients/${patientId}/accessible`),
      availableServices: (query) => Service.get(`/patients/${patientId}/available_service_lines`, query),
      sendWorkNote: (data) => Service.post(`/patients/${patientId}/work_notes`, data),
      sendPushNotification: (data) => Service.post(`/patients/${patientId}/push_notifications`, data),
      todos: {
        list: () => Service.get(`/patients/${patientId}/todos`).then(deserializeTodos),
        id: (todoId) => ({
          update: (data) => Service.patch(`/patients/${patientId}/todos/${todoId}`, data),
        }),
      },
      verifications: {
        send: (data) => Service.post(`/patients/${patientId}/verifications`, data),
        verify: (data, config) => Service.patch(`/patients/${patientId}/verifications`, data, config),
      },
      profilePicture: {
        create: (data) => Service.post(`/patients/${patientId}/profile_picture`, data),
        remove: () => Service.delete(`/patients/${patientId}/profile_picture`),
      },
      questionnaires: {
        list: (query) => Service.get(`/patients/${patientId}/questionnaires`, query),
        update: (data) => Service.patch(`/patients/${patientId}/questionnaires`, data),
      },
      photoID: {
        details: () =>
          Service.get(`/patients/${patientId}/photo_id`, undefined, { skipHandling: true }).catch(() =>
            Promise.resolve({
              id: 'error',
              photoId: imageNotAvailable,
            })
          ),
        create: (data) => Service.post(`/patients/${patientId}/photo_id`, data),
        remove: (data) => Service.delete(`/patients/${patientId}/photo_id`, data),
      },
      treatmentTypes: {
        list: () => Service.get(`/patients/${patientId}/treatment_types`),
        update: (data) => Service.put(`/patients/${patientId}/treatment_types`, data),
      },
      availabilities: {
        list: (query) => Service.get(`/patients/${patientId}/availabilities`, query),
      },
      cbos: {
        list: (query, config) => Service.get(`/patients/${patientId}/cbos`, query, config),
      },
      providers: {
        list: (query) => Service.get(`/patients/${patientId}/providers`, query),
      },
      gilead: {
        create: (data) => Service.post(`/patients/${patientId}/gilead/applications`, data),
        id: (gileadApplicationId) => ({
          details: () => Service.get(`/patients/${patientId}/gilead/applications/${gileadApplicationId}`),
          update: (data) => Service.put(`/patients/${patientId}/gilead/applications/${gileadApplicationId}`, data),
          patientSign: (data) => Service.put(`/patients/${patientId}/gilead/applications/${gileadApplicationId}/sign`, data),
          providerSign: (data) => Service.put(`/patients/${patientId}/gilead/applications/${gileadApplicationId}/provider_sign`, data),
          decline: (data) => Service.post(`/patients/${patientId}/gilead/applications/${gileadApplicationId}/decline`, data),
        }),
      },
      consents: {
        create: () => Service.post(`/patients/${patientId}/send_consent`),
        list: (query) => Service.get(`/patients/${patientId}/consents`, query),
        agree: (data, config) => Service.patch(`/patients/${patientId}/consents`, data, config),
      },
      priorAuth: {
        submit: (data) => Service.post(`/patients/${patientId}/prior_auths`, data),
        preview: (query) => Service.get(`/patients/${patientId}/prior_auths/preview`, query, fileConfig),
      },
      breakGlass: {
        unlock: (data) => Service.post(`/patients/${patientId}/break_glass`, data),
        reset: (config) => Service.delete(`/patients/${patientId}/break_glass`, undefined, config),
      },
      events: {
        list: (query) => Service.get(`/patients/${patientId}/events`, query),
        export: () => Service.get(`/patients/${patientId}/events/export`, fileConfig),
      },
      documents: {
        list: (query) => Service.get(`/patients/${patientId}/documents`, query),
        create: (data, config) => Service.post(`/patients/${patientId}/documents`, data, config),
        id: (documentId) => ({
          details: () => Service.get(`/patients/${patientId}/documents/${documentId}`),
          update: (data) => Service.patch(`/patients/${patientId}/documents/${documentId}`, data),
          remove: () => Service.delete(`/patients/${patientId}/documents/${documentId}`),
        }),
      },
      assessments: {
        list: (query) => Service.get(`/patients/${patientId}/assessments`, query),
        id: (assessmentId) => ({
          details: () => Service.get(`/patients/${patientId}/assessments/${assessmentId}`),
        }),
      },
      insurances: {
        list: (query) => Service.get(`/patients/${patientId}/insurances`, query),
        create: (data) => Service.post(`/patients/${patientId}/insurances`, data),
        summary: () => Service.get(`/patients/${patientId}/insurance_summary`),
        id: (insuranceId) => ({
          details: (config) => Service.get(`/patients/${patientId}/insurances/${insuranceId}`, undefined, config),
          update: (data) => Service.patch(`/patients/${patientId}/insurances/${insuranceId}`, data),
          remove: () => Service.delete(`/patients/${patientId}/insurances/${insuranceId}`),
        }),
      },
      healthHistory: {
        list: (query) => Service.get(`/patients/${patientId}/health_histories`, query),
        export: () => Service.get(`/patients/${patientId}/health_histories/export`, undefined, fileConfig),
        notes: {
          list: (query) => Service.get(`/patients/${patientId}/health_histories/notes`, query),
          create: (data) => Service.post(`/patients/${patientId}/health_histories/notes`, data),
        },
        alerts: {
          list: (query) => Service.get(`/patients/${patientId}/health_histories/alerts`, query),
          acknowledge: (alertId, data) => Service.patch(`/patients/${patientId}/health_histories/alerts/${alertId}`, data),
        },
        // TODO: REDO AND COMPLETE THIS
        // type: (type) => ({
        //   list: (query) => Service.get(`/patients/${patientId}/${type}`, query),
        //   create: (data) => Service.post(`/patients/${patientId}/${type}`, data),
        //   id: (id) => ({
        //     update: (data) => Service.patch(`/patients/${patientId}/${type}/${id}`, data),
        //     remove: () => Service.delete(`/patients/${patientId}/${type}/${id}`),
        //   }),
        // }),
      },
      appointments: {
        list: (query) => Service.get(`/patients/${patientId}/appointments`, query),
        create: (data) => Service.post(`/patients/${patientId}/appointments`, data),
        id: (appointmentId) => ({
          details: () => Service.get(`/patients/${patientId}/appointments/${appointmentId}`),
          update: (data) => Service.patch(`/patients/${patientId}/appointments/${appointmentId}`, data),
          cancel: () => Service.patch(`/patients/${patientId}/appointments/${appointmentId}/cancel`),
          invite: () => Service.post(`/patients/${patientId}/appointments/${appointmentId}/send_invite`),
          joinWaitingRoom: () => Service.post(`/patients/${patientId}/appointments/${appointmentId}/join_waiting_room`),
          completeVideo: () => Service.post(`/patients/${patientId}/appointments/${appointmentId}/complete_video`),
          soap: {
            schema: (query) =>
              Service.get(`/patients/${patientId}/appointments/${appointmentId}/soap_notes`, query, { skipDeserialization: true })
                .then((resp) => deserialize(resp)?.data)
                .then(deserializeEmbeddedSoapNote),
            update: (data) =>
              Service.patch(`/patients/${patientId}/appointments/${appointmentId}/soap_notes`, data, { skipDeserialization: true })
                .then((resp) => deserialize(resp)?.data)
                .then(deserializeEmbeddedSoapNote),
          },
        }),
      },
      prescriptions: {
        list: (query) => Service.get(`/patients/${patientId}/prescriptions`, query),
        id: (prescriptionId) => ({
          details: (config) => Service.get(`/patients/${patientId}/prescriptions/${prescriptionId}`, undefined, config),
        }),
      },
      labs: {
        list: (query) => Service.get(`/patients/${patientId}/labs`, query),
        order: (data) => Service.post(`/patients/${patientId}/labs`, data),
        vendors: (query) => Service.get(`/patients/${patientId}/lab_vendors`, query),
        files: {
          id: (fileId) => ({
            details: () => Service.get(`/patients/${patientId}/labs_files/${fileId}`),
            rename: (data) => Service.patch(`/patients/${patientId}/labs_files/${fileId}`, data),
          }),
        },
        id: (labId) => ({
          details: () => Service.get(`/patients/${patientId}/labs/${labId}`),
          update: (data) => Service.patch(`/patients/${patientId}/labs/${labId}`, data),
          notes: {
            list: (query) => Service.get(`/patients/${patientId}/labs/${labId}/notes`, query),
            create: (data) => Service.post(`/patients/${patientId}/labs/${labId}/notes`, data),
          },
        }),
      },
      roiMedicalRecords: {
        create: (data) => Service.post(`/patients/${patientId}/roi_medical_records`, data),
        preview: (query) => Service.get(`/patients/${patientId}/roi_medical_records/preview`, query, fileConfig),
        id: (medicalRecordId) => ({
          details: () => Service.get(`/patients/${patientId}/roi_medical_records/${medicalRecordId}`),
          fax: (data) => Service.post(`/patients/${patientId}/roi_medical_records/${medicalRecordId}/fax`, data),
        }),
      },
      roiRequests: {
        create: () => Service.post(`/patients/${patientId}/roi_requests`),
        id: (roiRequestId) => ({
          details: () => Service.get(`/patients/${patientId}/roi_requests/${roiRequestId}`),
          sign: (data) => Service.patch(`/patients/${patientId}/roi_requests/${roiRequestId}`, data),
          decline: (data) => Service.post(`/patients/${patientId}/roi_requests/${roiRequestId}/decline`, data),
        }),
      },
      notes: {
        create: (data) => Service.post(`/patients/${patientId}/notes`, data),
        list: (query) => Service.get(`/patients/${patientId}/notes`, query),
      },
      tasks: {
        list: (query) => Service.get(`/patients/${patientId}/tasks`, query),
        id: (taskId) => ({
          details: () => Service.get(`/patients/${patientId}/tasks/${taskId}`),
          notes: {
            create: (data) => Service.post(`/patients/${patientId}/tasks/${taskId}/notes`, data),
            list: (query) => Service.get(`/patients/${patientId}/tasks/${taskId}/notes`, query),
          },
        }),
      },
      insurancePartners: {
        update: (data) => Service.post(`/patients/${patientId}/cbo_insurance_partners`, data),
        invite: () => Service.post(`/patients/${patientId}/cbo_insurance_partners/send_invite`),
      },
      encounters: {
        create: (data) => Service.post(`/patients/${patientId}/encounters`, data),
        list: (query) => Service.get(`/patients/${patientId}/encounters`, query),
        available: (query) => Service.get(`/patients/${patientId}/encounter_types`, query),
        id: (encounterId) => ({
          details: () => Service.get(`/patients/${patientId}/encounters/${encounterId}`),
          update: (data) => Service.patch(`/patients/${patientId}/encounters/${encounterId}`, data),
          remove: () => Service.delete(`/patients/${patientId}/encounters/${encounterId}`),
          notes: {
            create: (data) => Service.post(`/patients/${patientId}/encounters/${encounterId}/notes`, data),
            list: (query) => Service.get(`/patients/${patientId}/encounters/${encounterId}/notes`, query),
          },
          appointments: {
            list: (query) => Service.get(`/patients/${patientId}/encounters/${encounterId}/appointments`, query),
            create: (data) => Service.post(`/patients/${patientId}/encounters/${encounterId}/appointments`, data),
            scheduled: {
              create: (data) => Service.post(`/patients/${patientId}/encounters/${encounterId}/scheduled_appointments`, data),
              id: (appointmentId) => ({
                update: (data) =>
                  Service.patch(`/patients/${patientId}/encounters/${encounterId}/scheduled_appointments/${appointmentId}`, data),
              }),
            },
            id: (appointmentId) => ({
              submitSoap: (data) => Service.patch(`/patients/${patientId}/encounters/${encounterId}/appointments/${appointmentId}`, data),
            }),
          },
          availabilities: {
            list: (query) => Service.get(`/patients/${patientId}/encounters/${encounterId}/availabilities`, query),
          },
          labs: {
            list: (query) => Service.get(`/patients/${patientId}/encounters/${encounterId}/labs`, query),
          },
          prescriptions: {
            list: (query) => Service.get(`/patients/${patientId}/encounters/${encounterId}/prescriptions`, query),
          },
          vaccines: {
            list: (query) => Service.get(`/patients/${patientId}/encounters/${encounterId}/vaccine_orders`, query),
            create: (data) => Service.post(`/patients/${patientId}/encounters/${encounterId}/vaccine_orders`, data),
          },
          assessments: {
            list: (query) => Service.get(`/patients/${patientId}/encounters/${encounterId}/assessments`, query),
            create: (data) => Service.post(`/patients/${patientId}/encounters/${encounterId}/assessments`, data),
            id: (assessmentId) => ({
              review: () => Service.post(`/patients/${patientId}/encounters/${encounterId}/assessments/${assessmentId}/review`),
              resend: () => Service.put(`/patients/${patientId}/encounters/${encounterId}/assessments/${assessmentId}/resend`),
            }),
          },
          referrals: {
            list: (query) => Service.get(`/patients/${patientId}/encounters/${encounterId}/referrals`, query),
            create: (data) => Service.post(`/patients/${patientId}/encounters/${encounterId}/referrals`, data),
            preview: (query) => Service.get(`/patients/${patientId}/encounters/${encounterId}/referrals/preview`, query, fileConfig),
          },
          morbidities: {
            list: (query) => Service.get(`/patients/${patientId}/encounters/${encounterId}/doh_morbidities`, query),
            create: (data) => Service.post(`/patients/${patientId}/encounters/${encounterId}/doh_morbidities`, data),
            preview: (query) => Service.get(`/patients/${patientId}/encounters/${encounterId}/doh_morbidities/preview`, query, fileConfig),
          },
        }),
      },
    }),
  },
  appointments: {
    list: (query) => Service.get('/appointments', query),
    id: (appointmentId) => ({
      details: (query, config) => Service.get(`/appointments/${appointmentId}`, query, config),
      soap: () => Service.get(`/appointments/${appointmentId}/soap_note`),
      avs: () => Service.get(`/appointments/${appointmentId}/after_visit_summary`),
      labs: (query) => Service.get(`/appointments/${appointmentId}/labs`, query),
      prescriptions: (query) => Service.get(`/appointments/${appointmentId}/prescriptions`, query),
      videoToken: (query) => Service.get(`/appointments/${appointmentId}/video_token`, query),
      markAsMissed: () => Service.post(`/appointments/${appointmentId}/set_missed`),
      markAsCanceled: () => Service.post(`/appointments/${appointmentId}/set_canceled`),
      markAsFailed: (data) => Service.post(`/appointments/${appointmentId}/fail`, data),
      notes: {
        list: (query) => Service.get(`/appointments/${appointmentId}/notes`, query),
        create: (data) => Service.post(`/appointments/${appointmentId}/notes`, data),
      },
    }),
  },
  availabilities: {
    declined: (query) => Service.get('/availabilities/declined', query),
    logs: (query) => Service.get('/availabilities/authorization_logs', query),
    providers: {
      list: (query) => Service.get('/availabilities/providers', query),
      id: (providerId) => ({
        pending: (query) => Service.get(`/availabilities/providers/${providerId}/pending_authorization`, query),
      }),
    },
    id: (availabilityId) => ({
      approve: () => Service.put(`/availabilities/${availabilityId}/authorizations`),
      decline: () => Service.delete(`/availabilities/${availabilityId}/authorizations`),
    }),
  },
  providers: {
    list: (query) => Service.get('/providers', query),
    availabilitySummary: (query) => Service.get('/providers/availabilities_summary', query),
    id: (providerId) => ({
      details: () => Service.get(`/providers/${providerId}`),
      metrics: () => Service.get(`/providers/${providerId}/metrics`),
      appointments: {
        list: (query) => Service.get(`/providers/${providerId}/appointments`, query),
        dashboard: (query, config) => Service.get(`/providers/${providerId}/dashboard_next_appointments`, query, config),
        id: (appointmentId) => ({
          start: () => Service.post(`/providers/${providerId}/appointments/${appointmentId}/start`),
          markAsCompleted: () => Service.post(`/providers/${providerId}/appointments/${appointmentId}/complete`),
          completeCall: () => Service.post(`/providers/${providerId}/appointments/${appointmentId}/complete_call`),
          createSwapType: () => Service.post(`/providers/${providerId}/appointments/${appointmentId}/create_swap_type`),
        }),
        adHoc: {
          list: (query) => Service.get(`/providers/${providerId}/ad_hoc_appointments`, query),
          create: (data) => Service.post(`/providers/${providerId}/ad_hoc_appointments`, data),
          id: (appointmentId) => ({
            update: (data) => Service.patch(`/providers/${providerId}/ad_hoc_appointments/${appointmentId}`, data),
            remove: () => Service.delete(`/providers/${providerId}/ad_hoc_appointments/${appointmentId}`),
            notes: {
              list: (query) => Service.get(`/providers/${providerId}/ad_hoc_appointments/${appointmentId}/notes`, query),
              create: (data) => Service.post(`/providers/${providerId}/ad_hoc_appointments/${appointmentId}/notes`, data),
            },
          }),
        },
      },
      encounters: {
        completed: (query) => Service.get(`/providers/${providerId}/completed_encounters`, query),
      },
      labs: {
        dashboard: (query) => Service.get(`/providers/${providerId}/dashboard_labs_review`, query).then(mapDashboardLabs),
      },
      assessments: {
        dashboard: (query, config) => Service.get(`/providers/${providerId}/dashboard_assessments_review`, query, config),
      },
      par: {
        list: (query) => Service.get(`/providers/${providerId}/par_tasks`, query),
        id: (taskId) => ({
          complete: (data) => Service.post(`/providers/${providerId}/par_tasks/${taskId}/mark_completed`, data),
        }),
      },
      cbos: {
        list: (query, config) => Service.get(`/providers/${providerId}/cbos`, query, config),
      },
      prescriptions: {
        url: (data) => Service.post(`/providers/${providerId}/prescriptions/rx_url`, data),
        sync: (data) => Service.post(`/providers/${providerId}/prescriptions/sync`, data),
      },
      availabilities: {
        list: (query) => Service.get(`/providers/${providerId}/availabilities`, query),
        monthly: (query) =>
          Service.get(`/providers/${providerId}/availabilities/monthly_count`, query).then((data) =>
            data.reduce((acc, day) => {
              Object.keys(day).forEach((date) => (acc[date] = day[date]))
              return acc
            }, {})
          ),
        create: (data) => Service.post(`/providers/${providerId}/availabilities`, data),
        createRange: (data) => Service.post(`/providers/${providerId}/availabilities/range`, data),
        id: (availabilityId) => ({
          update: (data) => Service.patch(`/providers/${providerId}/availabilities/${availabilityId}`, data),
          remove: () => Service.delete(`/providers/${providerId}/availabilities/${availabilityId}`),
        }),
      },
      adminTimes: {
        list: (query) => Service.get(`/providers/${providerId}/admin_times`, query),
        create: (data) => Service.post(`/providers/${providerId}/admin_times`, data),
        id: (timeId) => ({
          update: (data) => Service.patch(`/providers/${providerId}/admin_times/${timeId}`, data),
          remove: () => Service.delete(`/providers/${providerId}/admin_times/${timeId}`),
        }),
      },
    }),
  },
  cbos: {
    list: (query, config) => Service.get('/cbos', query, config),
    coverage: () => Service.get('/cbos_map_coverages'),
    id: (cboId) => ({
      details: (query, config) => Service.get(`/cbos/${cboId}`, query, config),
      update: (data) => Service.patch(`/cbos/${cboId}`, data),
      metrics: () => Service.get(`/cbos/${cboId}/metrics`),
      providers: {
        list: (query) => Service.get(`/cbos/${cboId}/providers`, query),
        gaps: {
          availability: (query) => Service.get(`/cbos/${cboId}/providers/availability_gaps`, query).then(mapGaps),
          weekly: (query) => Service.get(`/cbos/${cboId}/providers/weekly_gaps`, query),
        },
      },
      patients: {
        former: {
          list: (query) => Service.get(`/cbos/${cboId}/former_patients`, query),
          id: (patientId) => ({
            details: () => Service.get(`/cbos/${cboId}/former_patients/${patientId}`),
            encounters: {
              list: (query) => Service.get(`/cbos/${cboId}/former_patients/${patientId}/encounters`, query),
            },
          }),
        },
      },
      ruleSets: {
        list: (query, config) => Service.get(`/cbos/${cboId}/rule_sets`, query, config).then(mapRuleSets),
        update: (data) => Service.patch(`/cbos/${cboId}/rule_sets`, data).then(mapRuleSets),
      },
      priority: {
        add: (data) => Service.patch(`/cbos/${cboId}/priority_by_state`, data),
        remove: (data) => Service.delete(`/cbos/${cboId}/priority_by_state`, data),
      },
      pharmacies: {
        contracted: {
          list: (query) => Service.get(`/cbos/${cboId}/contracted_pharmacies`, query),
        },
      },
    }),
  },
  pharmacies: {
    list: (query) => Service.get('/pharmacies', query),
    create: (data) => Service.post('/pharmacies', data),
    export: (query) => Service.get('/pharmacies/reports/patient_assignment', query, fileConfig),
    id: (pharmacyId) => ({
      details: () => Service.get(`/pharmacies/${pharmacyId}`),
      update: (data) => Service.patch(`/pharmacies/${pharmacyId}`, data),
      remove: () => Service.delete(`/pharmacies/${pharmacyId}`),
      updateIndicators: (data) => Service.patch(`/pharmacies/${pharmacyId}/update_24h_and_pep`, data),
    }),
    tasks: {
      list: (query) => Service.get('/pharmacies/tasks', query),
      id: (taskId) => ({
        details: (query, config) => Service.get(`/pharmacies/tasks/${taskId}`, query, config),
        return: (data) => Service.post(`/pharmacies/tasks/${taskId}/mark_returned_pharmacy`, data),
      }),
    },
  },
  labs: {
    list: (query) => Service.get('/labs', query),
    export: (query) => Service.get('/labs/report', query, fileConfig),
    id: (labId) => ({
      details: () => Service.get(`/labs/${labId}`),
      update: (data) => Service.patch(`/labs/${labId}`, data),
      remove: () => Service.delete(`/labs/${labId}`),
      resubmit: () => Service.patch(`/labs/${labId}/resubmit`),
      notes: {
        list: (query) => Service.get(`/labs/${labId}/notes`, query),
        create: (data) => Service.post(`/labs/${labId}/notes`, data),
      },
    }),
    unmatched: {
      list: (query) => Service.get('/unmatched_labs', query),
      id: (labId) => ({
        details: () => Service.get(`/unmatched_labs/${labId}`),
        update: (data) => Service.patch(`/unmatched_labs/${labId}`, data),
        remove: () => Service.delete(`/unmatched_labs/${labId}`),
      }),
    },
  },
  prescriptions: {
    list: (query) => Service.get('/prescriptions', query),
    export: (query) => Service.get('/prescriptions/report', query, fileConfig),
    id: (prescriptionId) => ({
      details: () => Service.get(`/prescriptions/${prescriptionId}`),
      update: (data) => Service.patch(`/prescriptions/${prescriptionId}`, data),
      remove: () => Service.delete(`/prescriptions/${prescriptionId}`),
      notes: {
        list: (query) => Service.get(`/prescriptions/${prescriptionId}/notes`, query),
        create: (data) => Service.post(`/prescriptions/${prescriptionId}/notes`, data),
      },
    }),
  },
  assessments: {
    pending: {
      list: (query) => Service.get('/assessments/pending_qha', query),
    },
  },
  users: {
    id: (userId) => ({
      mentions: {
        list: (query) => Service.get(`/users/${userId}/message_mentions`, query),
      },
    }),
  },
  systemUsers: {
    list: (query) => Service.get('/system_users', query),
    id: (userId) => ({
      details: () => Service.get(`/system_users/${userId}`),
    }),
  },
  calls: {
    list: (query) => Service.get('/phone_calls', query),
    token: (query) => Service.get('/phone_calls/phone_token', query),
  },
  snippets: {
    list: (query) => Service.get('/snippets', query),
    create: (data, config) => Service.post('/snippets', data, config),
    id: (snippetId) => ({
      details: () => Service.get(`/snippets/${snippetId}`),
      update: (data, config) => Service.patch(`/snippets/${snippetId}`, data, config),
      setStatus: (data) => Service.patch(`/snippets/${snippetId}/set_status`, data),
    }),
  },
  sops: {
    list: (query) => Service.get('/sops', query),
    create: (data, config) => Service.post('/sops', data, config),
    id: (sopId) => ({
      details: () => Service.get(`/sops/${sopId}`),
      update: (data, config) => Service.patch(`/sops/${sopId}`, data, config),
      remove: () => Service.delete(`/sops/${sopId}`),
    }),
  },
}

// Deserialize the response data separately for each type of todo item because of special serialization.
const deserializeTodos = (data) => {
  return data.map((item) => {
    if ([TodoType.QuestLabOrdered, TodoType.LabsReceived].includes(item.type)) {
      const { serializable_lab: serializableLab, ...rest } = item
      return {
        ...rest,
        lab: deserialize(serializableLab, { transformKeys: 'camelCase' })?.data ?? {},
      }
    }
    if (item.type === TodoType.Appointment) {
      const { serializable_appointment: serializableAppointment, ...rest } = item
      return {
        ...rest,
        appointment: deserialize(serializableAppointment, { transformKeys: 'camelCase' })?.data ?? {},
      }
    }
    if (item.type === TodoType.Insurance) {
      const { serializable_pharmacy: data, ...rest } = item
      const pharmacy = deserialize(data, { transformKeys: 'camelCase' })?.data ?? {}
      return { ...rest, pharmacy }
    }
    if (item.type === TodoType.Generic) {
      const { serializable_todo: data, ...rest } = item
      const todo = deserialize(data, { transformKeys: 'camelCase' })?.data ?? {}
      return { ...rest, todo }
    }
    return item
  })
}

function deserializeEmbeddedSoapNote(data) {
  if (data.soap_note && 'string' === typeof data.soap_note && data.soap_note.length) {
    data.soap_note = JSON.parse(data.soap_note)
  } else {
    data.soap_note = {}
  }

  return data
}

const mapRuleSets = (data) => ({
  grant340BInformation: JSON.parse(data?.grant340BInformation ?? '{}'),
  additionalServices: JSON.parse(data?.additionalServices ?? '{}'),
  patientCare: JSON.parse(data?.patientCare ?? '{}'),
  financials: JSON.parse(data?.financials ?? '{}'),
  contacts: JSON.parse(data?.contacts ?? '[]'),
  prescriptionPartners: JSON.parse(data?.prescriptionPartners ?? '[]'),
})

const mapDashboardLabs = (data) => ({
  review: deserialize(data.review_status, { transformKeys: 'camelCase' })?.data ?? [],
  followUp: deserialize(data.follow_up_status, { transformKeys: 'camelCase' })?.data ?? [],
  positive: deserialize(data.positive_status, { transformKeys: 'camelCase' })?.data ?? [],
})

function mapGaps(data) {
  const appointments = Object.entries(data.appointments).reduce((acc, [key, value]) => {
    acc[key] = deserialize(value, { transformKeys: 'camelCase' })?.data ?? []
    return acc
  }, {})

  const availabilities = Object.entries(data.availabilities).reduce((acc, [key, value]) => {
    acc[key] = deserialize(value, { transformKeys: 'camelCase' })?.data ?? []
    return acc
  }, {})

  return {
    timezone: data.timezone,
    appointments,
    availabilities,
    providers: deserialize(data.providers, { transformKeys: 'camelCase' })?.data ?? [],
    gaps: deserialize(data.gaps, { transformKeys: 'camelCase' })?.data ?? [],
  }
}

export default API
