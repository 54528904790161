import { NotificationType } from '@pages/Notifications/Notifications.utils'

export const notificationToLabel = (notification) => {
  if (notification?.type === NotificationType.Message) {
    return 'New Message'
  }
  return 'New Notification'
}

export const mapNotification = (notification) => ({
  id: notification.notification_id,
  authorId: notification.author_id,
  read: false,
  type: notification.type,
  properties: {
    id: notification.properties.id,
    patientId: notification.properties.patient_id,
    messageId: notification.properties.message_id,
    patientName: notification.properties.patient_name,
    cboName: notification.properties.cbo_name,
    replyingMessage: notification.properties.replying_message,
    endTimeTz: notification.properties.end_time_tz,
    startTimeTz: notification.properties.start_time_tz,
  },
})
