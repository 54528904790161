import useQuery from '@shared/hooks/src/useQuery'
import { QK } from '@shared/utils'

import UsersApi from '@services/Users.api'

export function useUser(userId) {
  return useQuery({
    queryKey: QK.users.id(userId).card,
    queryFn: () => UsersApi.userCard(userId, { skipHandling: true }),
    enabled: Boolean(userId),
  })
}
