import { useCallback } from 'react'
import toast from 'react-hot-toast'
import { useMutation } from '@tanstack/react-query'
import dayjs from 'dayjs'

import { queryClient } from '@shared/providers/src/QueryClientProvider'
import API from '@shared/services/src/API'
import { extendCache, filterCache, handleError, mapCache, mapCacheWithFn, QK } from '@shared/utils'

import { FORMAT, LIMIT } from '../Availability.hooks'

export function useAvailabilityRemove(providerId) {
  const remove = useMutation({
    mutationFn: (id) => API.providers.id(providerId).availabilities.id(id).remove(),
  })

  return useCallback(
    (id) => {
      return remove.mutateAsync(id).then((resp) => {
        const updater = filterCache((a) => a.id !== id)

        queryClient.invalidateQueries({ queryKey: QK.providers.id(providerId).availabilities.months.lists })
        queryClient.setQueriesData({ queryKey: QK.providers.id(providerId).availabilities.lists }, updater)
        queryClient.setQueriesData({ queryKey: QK.availabilities.providers.id(providerId).pending.lists }, updater)
      })
    },
    [providerId, remove]
  )
}

export function useAvailabilityCreate(providerId) {
  const create = useMutation({
    mutationFn: (data) => API.providers.id(providerId).availabilities.create(data),
  })

  return useCallback(
    (data) => {
      return create.mutateAsync(data).then((newAvailability) => {
        queryClient.invalidateQueries({ queryKey: QK.providers.id(providerId).availabilities.months.lists })

        queryClient.setQueriesData(
          {
            queryKey: QK.providers.id(providerId).availabilities.lists,
            predicate: ({ queryKey }) => {
              const queryParam = queryKey[queryKey.length - 1]
              if (!queryParam || !queryParam.start_date || !queryParam.end_date) return false
              const start = dayjs(queryParam.start_date, FORMAT)
              const end = dayjs(queryParam.end_date, FORMAT)
              return dayjs(data.date_formatted, 'YYYY-MM-DD').isBetween(start, end, 'day', '[]')
            },
          },
          extendCache((list) => [...list, newAvailability].sort(sortFn), LIMIT)
        )
      })
    },
    [create, providerId]
  )
}

export function useAvailabilityEdit(providerId) {
  const edit = useMutation({
    mutationFn: ({ id, ...data }) => API.providers.id(providerId).availabilities.id(id).update(data),
  })

  return useCallback(
    (data) => {
      return edit.mutateAsync(data).then((availability) => {
        const updater = mapCacheWithFn((list) => list.map((a) => (a.id === availability.id ? availability : a)).sort(sortFn))

        queryClient.setQueriesData({ queryKey: QK.providers.id(providerId).availabilities.lists }, updater)
        queryClient.setQueriesData({ queryKey: QK.availabilities.providers.id(providerId).pending.lists }, updater)
      })
    },
    [edit, providerId]
  )
}

export function useAvailabilityDecline() {
  const update = useMutation({
    mutationFn: ({ availabilityId }) => API.availabilities.id(availabilityId).decline(),
    onSuccess: (availability, { providerId }) => {
      const updater = filterCache((a) => a.id !== availability.id)
      queryClient.setQueriesData({ queryKey: QK.providers.id(providerId).availabilities.lists }, updater)
      queryClient.setQueriesData({ queryKey: QK.availabilities.providers.id(providerId).pending.lists }, updater)
    },
  })

  return useCallback(
    (providerId, availabilityId) => {
      return update
        .mutateAsync({ providerId, availabilityId })
        .then(() => toast('Availability declined'))
        .catch((e) => handleError(e, { showResponse: true }))
    },
    [update]
  )
}

export function useAvailabilityApprove() {
  const update = useMutation({
    mutationFn: ({ availabilityId }) => API.availabilities.id(availabilityId).approve(),
    onSuccess: (availability, { providerId }) => {
      queryClient.setQueriesData(
        { queryKey: QK.providers.id(providerId).availabilities.lists },
        mapCache((a) => (a.id === availability.id ? { ...a, ...availability } : a))
      )
      queryClient.setQueriesData(
        { queryKey: QK.availabilities.providers.id(providerId).pending.lists },
        filterCache((a) => a.id !== availability.id)
      )
    },
  })

  return useCallback(
    (providerId, availabilityId) => {
      return update
        .mutateAsync({ providerId, availabilityId })
        .then(() => toast('Availability approved'))
        .catch((e) => handleError(e, { showResponse: true }))
    },
    [update]
  )
}

export function useAdminTimeRemove(providerId) {
  const remove = useMutation({
    mutationFn: (id) => API.providers.id(providerId).adminTimes.id(id).remove(),
  })

  return useCallback(
    (id) => {
      return remove.mutateAsync(id).then(() => {
        return queryClient.setQueriesData(
          { queryKey: QK.providers.id(providerId).adminTimes.lists },
          filterCache((a) => a.id !== id)
        )
      })
    },
    [providerId, remove]
  )
}

export function useAdminTimeCreate(providerId) {
  const create = useMutation({
    mutationFn: (data) => API.providers.id(providerId).adminTimes.create(data),
  })

  return useCallback(
    (data) => {
      return create.mutateAsync(data).then((newTime) => {
        queryClient.setQueriesData(
          {
            queryKey: QK.providers.id(providerId).adminTimes.lists,
            predicate: ({ queryKey }) => {
              const queryParam = queryKey[queryKey.length - 1]
              if (!queryParam || !queryParam.start_date_time || !queryParam.end_date_time) return false
              const start = dayjs(queryParam.start_date_time, FORMAT)
              const end = dayjs(queryParam.end_date_time, FORMAT)
              return dayjs(data.date_formatted, 'YYYY-MM-DD').isBetween(start, end, 'day', '[]')
            },
          },
          extendCache((list) => [...list, newTime].sort(sortFn), LIMIT)
        )
      })
    },
    [create, providerId]
  )
}

export function useAdminTimeEdit(providerId) {
  const edit = useMutation({
    mutationFn: ({ id, ...data }) => API.providers.id(providerId).adminTimes.id(id).update(data),
  })

  return useCallback(
    (data) => {
      return edit.mutateAsync(data).then((time) => {
        return queryClient.setQueriesData(
          { queryKey: QK.providers.id(providerId).adminTimes.lists },
          mapCacheWithFn((list) => list.map((a) => (a.id === time.id ? time : a)).sort(sortFn))
        )
      })
    },
    [edit, providerId]
  )
}

export function useAdHocAppointmentRemove(providerId) {
  const remove = useMutation({
    mutationFn: (appointmentId) => API.providers.id(providerId).appointments.adHoc.id(appointmentId).remove(),
  })

  return useCallback(
    (id) => {
      return remove.mutateAsync(id).then(() => {
        return queryClient.setQueriesData(
          { queryKey: QK.providers.id(providerId).adHocAppointments.lists },
          filterCache((a) => a.id !== id)
        )
      })
    },
    [providerId, remove]
  )
}

export function useAdHocAppointmentCreate(providerId) {
  const create = useMutation({
    mutationFn: (data) => API.providers.id(providerId).appointments.adHoc.create(data),
  })

  return useCallback(
    (data) => {
      return create.mutateAsync(data).then((newAppointment) => {
        queryClient.setQueriesData(
          {
            queryKey: QK.providers.id(providerId).adHocAppointments.lists,
            predicate: ({ queryKey }) => {
              const queryParam = queryKey[queryKey.length - 1]
              if (!queryParam || !queryParam.start_date || !queryParam.end_date) return false
              const start = dayjs(queryParam.start_date, FORMAT)
              const end = dayjs(queryParam.end_date, FORMAT)
              return dayjs(data.time, 'YYYY-MM-DD').isBetween(start, end, 'day', '[]')
            },
          },
          extendCache((list) => [...list, newAppointment].sort(sortFn), LIMIT)
        )
      })
    },
    [create, providerId]
  )
}

export function useAdHocAppointmentEdit(providerId) {
  const edit = useMutation({
    mutationFn: ({ id, ...data }) => API.providers.id(providerId).appointments.adHoc.id(id).update(data),
  })

  return useCallback(
    (data) => {
      return edit.mutateAsync(data).then((appointment) => {
        return queryClient.setQueriesData(
          { queryKey: QK.providers.id(providerId).adHocAppointments.lists },
          mapCacheWithFn((list) => list.map((a) => (a.id === appointment.id ? appointment : a)).sort(sortFn))
        )
      })
    },
    [edit, providerId]
  )
}

const sortFn = (a, b) => new Date(a.start) - new Date(b.start)
