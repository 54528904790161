import { baseExtensions } from '@shared/messaging/src/RichTextHelper'

import DateExtension from './DateExtension'
import MentionExtension from './MentionExtension'
import QPhrasesExtension from './QPhrasesExtension'
import SelectorExtension from './SelectorExtension'

export const extensions = [...baseExtensions, SelectorExtension, DateExtension, MentionExtension()]

export const getExtensions = ({
  conversationId = null,
  enableMentions = false,
  enableQPhrases = false,
  selectorsEditable = false,
} = {}) => [
  ...baseExtensions,
  SelectorExtension.configure({ editable: selectorsEditable }),
  DateExtension,
  ...(enableQPhrases ? [QPhrasesExtension] : []),
  ...(enableMentions ? [MentionExtension(conversationId)] : []),
]
