import { forwardRef, useEffect, useRef, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { IMaskInput } from 'react-imask'
import { yupResolver } from '@hookform/resolvers/yup'
import { generateHTML } from '@tiptap/core'
import omitBy from 'lodash/omitBy'
import without from 'lodash/without'
import * as Yup from 'yup'

import { richTextStyles } from '@shared/messaging/src/RichTextHelper'
import { isUserAllowed, RoleGuard, useMe } from '@shared/providers/src/MeProvider'
import { convertJsonToPlainText, handleError, UserRole, userRoleToLabel } from '@shared/utils'

import { outlineRichTextEditorStyling } from '@utils/StylesHelper'
import { CopyOutlinedIcon, DeleteOutlinedIcon, DownOutlinedIcon, EditOutlinedIcon, UpOutlinedIcon } from '@icons'
import {
  Box,
  Button,
  Chip,
  Collapse,
  Divider,
  IconButton,
  LoadingButton,
  MenuItem,
  Skeleton,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui-components'
import RichText, { getExtensions, RichTextEditor } from '@components/RichText'

const heightLimit = 50

export function QPhrase({ data, onUpdate, onRemove }) {
  const me = useMe()

  const contentRef = useRef(null)

  const [edit, setEdit] = useState(false)
  const [expanded, setExpanded] = useState(false)
  const [exceeds, setExceeds] = useState(false)

  useEffect(() => {
    if (contentRef.current) {
      setExceeds(contentRef.current.scrollHeight > heightLimit)
    }
  }, [data.value])

  const isPersonal = data.category === 'personal'
  const isEditingAllowed = isPersonal || isUserAllowed(me, [UserRole.Admin])

  return (
    <Stack p={1} spacing={1}>
      <Stack direction="row" alignItems="center" spacing={1}>
        <Typography variant="h5" noWrap sx={{ flex: '1 1 auto' }}>
          {data.title}
        </Typography>
        <Chip label={data.abbreviation} size="small" sx={{ maxWidth: 150 }} />
        <CopyButton value={data.value} />
        {isEditingAllowed && (
          <>
            <IconButton size="small" onClick={() => setEdit((o) => !o)}>
              <EditOutlinedIcon style={{ fontSize: 18 }} />
            </IconButton>
            <IconButton size="small" color="error" onClick={onRemove}>
              <DeleteOutlinedIcon style={{ fontSize: 18 }} />
            </IconButton>
          </>
        )}
      </Stack>

      {!isPersonal && (
        <RoleGuard allowed={[UserRole.Admin]}>
          <Stack direction="row" gap={1} flexWrap="wrap">
            {data.roles.length === Object.keys(UserRole).length - 1 ? (
              <Chip label="All Roles" variant="outlined" size="small" />
            ) : (
              data.roles.map((role) => <Chip key={role} variant="outlined" label={userRoleToLabel[role]} size="small" />)
            )}
          </Stack>
        </RoleGuard>
      )}

      <Collapse in={!edit}>
        <Collapse in={expanded} collapsedSize={heightLimit}>
          <Box key={data.value} ref={contentRef}>
            <RichText content={data.value} />
          </Box>
        </Collapse>
        {exceeds && (
          <Stack>
            <Button
              size="small"
              variant="outlined"
              onClick={() => setExpanded((prev) => !prev)}
              endIcon={expanded ? <UpOutlinedIcon /> : <DownOutlinedIcon />}
            >
              {expanded ? 'Hide' : 'Expand'}
            </Button>
          </Stack>
        )}
      </Collapse>

      <QPhraseForm data={data} open={edit} onClose={() => setEdit(false)} onUpdate={onUpdate} />

      <Divider />
    </Stack>
  )
}

QPhrase.Loading = function Loading() {
  return (
    <Stack p={1} spacing={1}>
      <Stack direction="row" alignItems="center" spacing={1}>
        <Typography variant="h5" noWrap sx={{ flex: '1 1 auto' }}>
          <Skeleton width={300} />
        </Typography>
        <Chip label={<Skeleton width={50} />} size="small" />
      </Stack>

      <Typography sx={{ height: heightLimit }}>
        <Skeleton width={400} />
      </Typography>

      <Divider />
    </Stack>
  )
}

export function QPhraseForm({ card = false, category = '', data, open, onClose, onUpdate }) {
  return (
    <Collapse in={open} unmountOnExit>
      <Form card={card} category={category} data={data} onClose={onClose} onUpdate={onUpdate} />
    </Collapse>
  )
}

function Form({ card = false, category, data, onClose, onUpdate }) {
  const editorRef = useRef()

  const me = useMe()
  const form = useForm({
    mode: 'all',
    resolver: yupResolver(validationSchema),
    defaultValues: {
      category: data?.category || category,
      title: data?.title || '',
      abbreviation: data?.abbreviation || '',
      roles: data?.roles || without(Object.values(UserRole), UserRole.Patient),
    },
  })

  const handleUpdate = (values) => {
    if (values.category === 'personal') values.roles = [me.role]
    const message = editorRef.current?.getJSON()
    values.value = JSON.stringify(message)

    return onUpdate(values)
      .then(onClose)
      .catch((e) => handleError(e, { showResponse: true }))
  }

  const categoryValue = form.watch('category')
  const isSubmitting = form.formState.isSubmitting

  return (
    <form onSubmit={form.handleSubmit(handleUpdate)}>
      <Stack spacing={2} mb={2} sx={card ? { border: '1px solid', borderColor: 'divider', borderRadius: 1, p: 2 } : undefined}>
        <Stack direction="row" spacing={2}>
          <Controller
            name="title"
            control={form.control}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                label="Title"
                variant="outlined"
                fullWidth
                disabled={isSubmitting}
                error={Boolean(fieldState.error)}
                helperText={fieldState.error?.message}
                sx={{ flex: 7 }}
              />
            )}
          />
          <Controller
            name="abbreviation"
            control={form.control}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                label="Abbreviation"
                variant="outlined"
                fullWidth
                disabled={isSubmitting}
                error={Boolean(fieldState.error)}
                helperText={fieldState.error?.message}
                InputProps={{ inputComponent: AbbreviationMask }}
                sx={{ flex: 3 }}
              />
            )}
          />
        </Stack>
        {categoryValue !== 'personal' && (
          <Controller
            name="roles"
            control={form.control}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                label="Roles"
                variant="outlined"
                fullWidth
                disabled={isSubmitting}
                select
                SelectProps={{ multiple: true }}
              >
                {Object.values(omitBy(UserRole, (role) => role === UserRole.Patient)).map((role) => (
                  <MenuItem key={role} value={role}>
                    {userRoleToLabel[role]}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
        )}

        <Box sx={[outlineRichTextEditorStyling, richTextStyles, { '& .tiptap': { minHeight: 150 } }]}>
          <RichTextEditor
            ref={editorRef}
            selectorEnabled
            dateEnabled
            extensions={getExtensions({ selectorsEditable: true })}
            initialValue={data?.value ? JSON.parse(data.value) : ''}
          />
        </Box>

        <Stack spacing={1}>
          <LoadingButton type="submit" disabled={editorRef.current?.isEmpty} loading={isSubmitting} variant="contained" color="primary">
            {data ? 'Edit' : 'Create'}
          </LoadingButton>
          <Button onClick={onClose} disabled={isSubmitting} variant="outlined">
            Cancel
          </Button>
        </Stack>
      </Stack>
    </form>
  )
}

export const AbbreviationMask = forwardRef(function AbbreviationMask(props, ref) {
  const { onChange, ...other } = props
  return (
    <IMaskInput
      {...other}
      mask={/^[a-z0-9]*$/}
      inputRef={ref}
      overwrite
      onAccept={(value) => {
        if (value !== props.value) {
          onChange({ target: { name: props.name, value } })
        }
      }}
    />
  )
})

function CopyButton({ value }) {
  const [tooltipOpen, setTooltipOpen] = useState(false)

  const handleCopy = () => {
    const json = JSON.parse(value)

    const items = [
      new ClipboardItem({
        'text/html': new Blob([generateHTML(json, getExtensions())], { type: 'text/html' }),
        'text/plain': new Blob([convertJsonToPlainText(json)], { type: 'text/plain' }),
      }),
    ]

    navigator.clipboard
      .write(items)
      .then(() => {
        setTooltipOpen(true)
        setTimeout(() => setTooltipOpen(false), 1000)
      })
      .catch((error) => toast.error(`Failed to copy: ${error}`))
  }

  return (
    <Tooltip open={tooltipOpen} title="Copied to Clipboard">
      <IconButton size="small" onClick={handleCopy}>
        <CopyOutlinedIcon style={{ fontSize: 18 }} />
      </IconButton>
    </Tooltip>
  )
}

export const validationSchema = Yup.object().shape({
  title: Yup.string().required('Required'),
  abbreviation: Yup.string().max(16, 'Exceeds 16 chars').required('Required'),
  roles: Yup.array().of(Yup.string()).min(1, 'At least one role is required'),
})
