import { useState } from 'react'
import { keepPreviousData } from '@tanstack/react-query'
import { useDebounce } from 'usehooks-ts'

import useLoadingState from '@shared/hooks/src/useLoadingState'
import { getTestId, UserRole } from '@shared/utils'

import { Autocomplete, CircularProgress, Stack, TextField, Typography } from '@mui-components'
import Avatar, { AvatarPlaceholder } from '@components/Avatar'

import { useUsers } from './SelectUser.hooks'
import styles from './SelectUser.styles'

/** Wrapper around MUI Autocomplete to find and select user */
export default function SelectUser({
  value: user,
  onChange,
  error,
  helperText,
  role,
  placeholder = 'Unassigned',
  loading: outsideLoading = false,
  hover = 'none',
  disableStyles = false,
  autoFocus = false,
  ...rest
}) {
  const testId = getTestId(rest, 'user-selector')

  const [interacted, setInteracted] = useState(false)
  const [inputValue, setInputValue] = useState('')

  const search = useDebounce(inputValue.trim(), 300)

  const { data, isPending, isRefreshing } = useUsers(
    { search, role, status: 'active' },
    {
      // Increase stale time to avoid unnecessary requests
      staleTime: 60 * 1000,
      placeholderData: keepPreviousData,
      enabled: interacted,
    }
  )

  const loading = useLoadingState((interacted && (isPending || isRefreshing)) || outsideLoading)

  return (
    <Autocomplete
      autoComplete
      selectOnFocus
      onFocus={() => {
        if (!interacted) setInteracted(true)
      }}
      forcePopupIcon={false}
      getOptionLabel={(option) => option.fullName}
      filterOptions={(x) => x}
      value={user}
      options={data || []}
      loading={loading}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      onChange={(event, user) => onChange(user)}
      onInputChange={(event, search) => {
        if (!event || event.type === 'click') return setInputValue('')
        setInputValue(search)
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          autoFocus={autoFocus}
          helperText={helperText}
          error={error}
          placeholder={placeholder}
          InputProps={{
            ...params.InputProps,
            startAdornment: user ? <Avatar size="xs" user={user} hover={hover} /> : <AvatarPlaceholder size="xs" />,
            endAdornment: (
              <>
                {loading ? <CircularProgress size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
          inputProps={{ ...params.inputProps, 'data-testid': `${testId}-input` }}
          sx={disableStyles ? undefined : styles.input}
        />
      )}
      renderOption={({ key, ...props }, user) => {
        const isPatient = user.role === UserRole.Patient

        return (
          <li key={user.id} {...props} data-testid={`${testId}-${user.fullName}`}>
            <Stack direction="row" alignItems="center" minWidth={0} spacing={1}>
              <Avatar size="xs" user={user} />
              <Typography noWrap>
                {user.fullName}
                {isPatient ? ` #${user.id}` : ''}
              </Typography>
            </Stack>
          </li>
        )
      }}
      {...rest}
    />
  )
}
