import { useLayoutEffect } from 'react'
import { useInfiniteQuery } from '@tanstack/react-query'
import dayjs from 'dayjs'
import { chain } from 'lodash'
import { useReadLocalStorage } from 'usehooks-ts'

import API from '@shared/services/src/API'
import { BuildEnv, pageParam, QK } from '@shared/utils'

export const FORMAT = 'YYYY-MM-DD HH:mm'
export const LIMIT = 50
const isProd = import.meta.env.VITE_BUILD_ENV === BuildEnv.Production

// Loads by LIMIT chunks until there are no more items
export default function useBlackoutPeriods({ startDate, endDate }) {
  const overriddenLimit = useReadLocalStorage('override-page-size') || LIMIT
  const limit = isProd ? LIMIT : overriddenLimit

  const query = { limit, start_date_time: startDate.format(FORMAT), end_date_time: endDate.format(FORMAT) }

  const queryResult = useInfiniteQuery({
    queryKey: QK.platform.blackouts.list(query),
    queryFn: ({ pageParam }) => API.platform.blackouts.list({ ...query, offset: pageParam * limit }),
    select: ({ pages }) => {
      const data = pages.flat()
      return chain(data)
        .groupBy((event) => {
          const date = event.allDay ? dayjs(event.allDayDate) : dayjs(event.start).tz(window.timezone)
          return date.format('YYYY-MM-DD')
        })
        .value()
    },
    initialPageParam: 0,
    getNextPageParam: pageParam(limit),
  })

  const { fetchNextPage, hasNextPage, isFetchingNextPage } = queryResult

  useLayoutEffect(() => {
    if (!isFetchingNextPage && hasNextPage) {
      fetchNextPage()
    }
  }, [fetchNextPage, hasNextPage, isFetchingNextPage])

  return queryResult
}
