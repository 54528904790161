import { useMutation } from '@tanstack/react-query'

import useQuery from '@shared/hooks/src/useQuery'
import { queryClient } from '@shared/providers/src/QueryClientProvider'
import API from '@shared/services/src/API'
import { QK } from '@shared/utils'

export function useCBORuleSets(cboId, options = {}) {
  return useQuery({
    queryKey: QK.cbos.id(cboId).ruleSets,
    queryFn: () => API.cbos.id(cboId).ruleSets.list(),
    ...options,
  })
}

export function useCBORuleSetsUpdate(cboId) {
  return useMutation({
    mutationFn: (data) => API.cbos.id(cboId).ruleSets.update(data),
    onSuccess: (data) => queryClient.setQueryData(QK.cbos.id(cboId).ruleSets, data),
  })
}
