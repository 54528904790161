import React, { useEffect, useImperativeHandle, useMemo, useState } from 'react'
import { keepPreviousData, useQuery } from '@tanstack/react-query'
import MentionExtensionBase from '@tiptap/extension-mention'
import { mergeAttributes, NodeViewWrapper, ReactNodeViewRenderer, ReactRenderer } from '@tiptap/react'
import { useDebounce } from 'usehooks-ts'

import ConversationsApi from '@shared/services/src/Conversations.api'
import { QK, userRoleToLabel } from '@shared/utils'

import { MedicalServiceIcon, PeopleIcon } from '@icons'
import { Box, Fade, List, ListItemButton, ListItemIcon, ListItemText, Paper, Popper, Typography } from '@mui-components'
import Avatar, { Offline } from '@components/Avatar'
import { UserCardPopper } from '@components/UserCard'

const MentionType = {
  User: 'user',
  Pharmacy: 'pharmacy',
  CareTeam: 'careteam',
}

const styles = {
  mention: {
    display: 'inline-flex',
    px: 0.5,
    borderRadius: 1,
  },
  list: {
    width: '100%',
    maxWidth: 400,
  },
}

// Custom node view component
const Mention = (props) => {
  const { id, label, type } = props.node.attrs

  return (
    <NodeViewWrapper style={{ display: 'inline' }}>
      <Box component="span" sx={[styles.mention, { backgroundColor: type === MentionType.User ? 'primary.200' : 'warning.lighter' }]}>
        {type === MentionType.User && (
          <UserCardPopper userId={id}>
            <Typography component="span" color="primary.main">
              @{label}
            </Typography>
          </UserCardPopper>
        )}
        {type === MentionType.Pharmacy && (
          <Typography component="span" color="warning.dark">
            @{label}
          </Typography>
        )}
        {type === MentionType.CareTeam && (
          <Typography component="span" color="warning.dark">
            @{label}
          </Typography>
        )}
      </Box>
    </NodeViewWrapper>
  )
}

// Custom mention popup component
const UsersList = React.forwardRef(({ clientRect, command, query: search, conversationId }, ref) => {
  const [selectedIndex, setSelectedIndex] = useState(0)
  const referenceEl = useMemo(() => (clientRect ? { getBoundingClientRect: clientRect } : null), [clientRect])
  const debouncedSearch = useDebounce(search, 300)

  const query = { term: debouncedSearch, limit: 10 }

  const { data } = useQuery({
    queryKey: QK.conversations.id(conversationId).users.search.list(query),
    queryFn: () => ConversationsApi.possibleUsers(conversationId, query),
    enabled: Boolean(conversationId) && conversationId !== 'new',
    placeholderData: keepPreviousData,
    select: (users) => {
      const utils = []
      if (MentionType.CareTeam.startsWith(debouncedSearch)) {
        utils.push({ id: MentionType.CareTeam, label: 'careteam', type: MentionType.CareTeam })
      }
      if (MentionType.Pharmacy.startsWith(debouncedSearch)) {
        utils.push({ id: MentionType.Pharmacy, label: 'pharmacy', type: MentionType.Pharmacy })
      }
      return [...utils, ...users.map((user) => ({ id: user.id, label: user.fullName, type: MentionType.User, data: user }))]
    },
  })

  // Reset selectedIndex when data changes
  useEffect(() => setSelectedIndex(0), [data])

  const selectItem = (index) => {
    const item = data?.[index]
    if (item) command(item)
  }

  const upHandler = () => setSelectedIndex((selectedIndex + data?.length - 1) % data?.length)
  const downHandler = () => setSelectedIndex((selectedIndex + 1) % data?.length)
  const enterHandler = () => selectItem(selectedIndex)

  useImperativeHandle(ref, () => ({
    onKeyDown: ({ event }) => {
      if (event.key === 'ArrowUp') {
        upHandler()
        return true
      }

      if (event.key === 'ArrowDown') {
        downHandler()
        return true
      }

      if (event.key === 'Enter') {
        enterHandler()
        return true
      }

      return false
    },
  }))

  return (
    <Popper
      id="check"
      placement="top-start"
      open={Boolean(clientRect) && data?.length > 0}
      anchorEl={referenceEl}
      transition
      sx={{ zIndex: 1300 }}
    >
      {({ TransitionProps }) => (
        <Fade {...TransitionProps}>
          <Paper>
            <List dense disablePadding sx={styles.list}>
              {data?.map(({ id, label, type, data }, index) => (
                <ListItemButton key={index} divider selected={selectedIndex === index} onClick={() => selectItem(index)}>
                  {type === MentionType.User ? (
                    <>
                      <Offline in={Boolean(data.outOfOfficeMessage)} overlap="rectangular">
                        <Avatar hover="card" user={data} size="md" />
                      </Offline>
                      <ListItemText
                        primary={data.fullName}
                        primaryTypographyProps={{ lineHeight: 1.5 }}
                        secondary={userRoleToLabel[data.role]}
                        secondaryTypographyProps={{ lineHeight: 1.5 }}
                        sx={{ pl: 1, my: 0 }}
                      />
                    </>
                  ) : (
                    <>
                      <ListItemIcon>
                        {id === MentionType.Pharmacy && <MedicalServiceIcon style={{ fontSize: 20 }} />}
                        {id === MentionType.CareTeam && <PeopleIcon style={{ fontSize: 20 }} />}
                      </ListItemIcon>
                      <ListItemText primary={`@${label}`} primaryTypographyProps={{ variant: 'h5' }} />
                    </>
                  )}
                </ListItemButton>
              ))}
            </List>
          </Paper>
        </Fade>
      )}
    </Popper>
  )
})

// Extend and configure the mention extension
const MentionExtension = (conversationId) => {
  const renderUsersPopup = () => {
    let component

    return {
      onStart: (props) => {
        component = new ReactRenderer(UsersList, {
          props: { ...props, conversationId },
          editor: props.editor,
        })
      },

      onUpdate(props) {
        component?.updateProps({ ...props, conversationId })
      },

      onKeyDown(props) {
        if (props.event.key === 'Escape') {
          component?.destroy()
          return true
        }

        return component.ref?.onKeyDown(props)
      },

      onExit() {
        component.destroy()
      },
    }
  }

  return MentionExtensionBase.extend({
    addNodeView() {
      return ReactNodeViewRenderer(Mention)
    },
    parseHTML() {
      return [{ tag: 'mention-component' }]
    },
    renderHTML({ HTMLAttributes }) {
      return ['mention-component', mergeAttributes(HTMLAttributes)]
    },
    addAttributes() {
      return {
        id: {
          default: null,
          parseHTML: (element) => element.getAttribute('data-id'),
          renderHTML: (attributes) => {
            if (!attributes.id) return {}
            return { 'data-id': attributes.id }
          },
        },
        label: {
          default: null,
          parseHTML: (element) => element.getAttribute('data-label'),
          renderHTML: (attributes) => {
            if (!attributes.label) return {}
            return { 'data-label': attributes.label }
          },
        },
        type: {
          default: MentionType.User,
          parseHTML: (element) => element.getAttribute('data-type'),
          renderHTML: (attributes) => {
            if (!attributes.type) return {}
            return { 'data-type': attributes.type }
          },
        },
      }
    },
  }).configure({
    deleteTriggerWithBackspace: true,
    suggestion: { allowSpaces: true, render: renderUsersPopup },
  })
}

export default MentionExtension
