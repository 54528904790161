import { useMutation } from '@tanstack/react-query'

import useQuery from '@shared/hooks/src/useQuery'
import { queryClient } from '@shared/providers/src/QueryClientProvider'
import API from '@shared/services/src/API'
import { filterCache, handleError, mapCache, QK } from '@shared/utils'

export function usePharmacy(pharmacyId, options = {}) {
  return useQuery({
    queryKey: QK.pharmacies.id(pharmacyId).details,
    queryFn: () => API.pharmacies.id(pharmacyId).details(),
    ...options,
  })
}

export function usePharmacyDelete() {
  return useMutation({
    mutationFn: (pharmacyId) => API.pharmacies.id(pharmacyId).remove(),
    onSuccess: (data, pharmacyId) => {
      queryClient.removeQueries({ queryKey: QK.pharmacies.id(pharmacyId).details })
      queryClient.setQueriesData(
        { queryKey: QK.pharmacies.lists },
        filterCache((old) => old.id !== pharmacyId)
      )
    },
    onError: handleError,
  })
}

export function usePharmacyUpdate(pharmacyId) {
  return useMutation({
    mutationFn: (data) => API.pharmacies.id(pharmacyId).update(data),
    onSuccess: (data) => {
      queryClient.setQueryData(QK.pharmacies.id(pharmacyId).details, data)
      queryClient.setQueriesData(
        { queryKey: QK.pharmacies.lists },
        mapCache((old) => (old.id === data.id ? { ...old, ...data } : old))
      )
    },
    onError: handleError,
  })
}

export function usePharmacyIndicatorsUpdate(pharmacyId) {
  return useMutation({
    mutationFn: (data) => API.pharmacies.id(pharmacyId).updateIndicators(data),
    onSuccess: (data) => {
      queryClient.setQueryData(QK.pharmacies.id(pharmacyId).details, data)
      queryClient.setQueriesData(
        { queryKey: QK.pharmacies.lists },
        mapCache((old) => (old.id === data.id ? { ...old, ...data } : old))
      )
    },
    onError: handleError,
  })
}

export function usePharmacyCreate() {
  return useMutation({
    mutationFn: (data) => API.pharmacies.create(data),
    onSuccess: (data) => {
      queryClient.setQueryData(QK.pharmacies.id(data.id).details, data)
      queryClient.invalidateQueries({ queryKey: QK.pharmacies.lists })
    },
    onError: handleError,
  })
}
