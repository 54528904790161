import dayjs from 'dayjs'

/**
 * Method to convert plain text to JSON
 */
export const convertPlainTextToJson = (plainText) => {
  if (!plainText) return { type: 'doc', content: [] }

  const content = plainText
    .split('\n')
    .flatMap((line, index, array) => {
      const nodes = [{ type: 'text', text: line }]
      if (index < array.length - 1) {
        nodes.push({ type: 'hardBreak' })
      }
      return nodes
    })
    .filter((node) => node.type !== 'text' || node.text !== '')

  return { type: 'doc', content: [{ type: 'paragraph', content }] }
}

/**
 * Method to convert JSON to plain text with custom formatting
 */
export const convertJsonToPlainText = (jsonContent) => {
  // Recursive function to handle nodes
  const convertToPlainText = (node, level = 0) => {
    let text = ''

    switch (node.type) {
      case 'doc':
        // Process each top-level node
        text += (node.content || []).map((child) => convertToPlainText(child, level)).join('')
        break

      case 'paragraph':
        // Process paragraph content and add a newline
        text += (node.content || []).map((child) => convertToPlainText(child, level)).join('') + '\n'
        break

      case 'text':
        // Process text and apply marks if any
        let textContent = node.text || ''
        if (node.marks) {
          node.marks.forEach((mark) => {
            if (mark.type === 'link') {
              textContent = `[${textContent}] (${mark.attrs.href})`
            }
            // Can be extended here in the future with other marks like bold or italic
          })
        }
        text += textContent
        break

      case 'mention':
        // Handle mention nodes by prefixing with '@'
        const mentionLabel = node.attrs.label || 'unknown'
        text += `@${mentionLabel} (${node.attrs.id})`
        break

      case 'select':
        const { selected, options = [] } = node.attrs
        if (options.length === 1) {
          text += selected
          break
        }
        text += `(${[selected, ...options.filter((o) => o !== selected)].join(', ')})`
        break

      case 'date':
        const date = dayjs(node.attrs.date)
        if (date.isValid()) {
          text += date.format('LL')
        }
        break

      case 'youtube':
        text += `(${node.attrs.src})`
        break

      case 'bulletList':
      case 'orderedList':
        // Process list items
        text += (node.content || [])
          .map((listItem, index) => {
            const prefix = node.type === 'orderedList' ? `${index + 1}. ` : '• '
            const indent = '  '.repeat(level)
            return indent + prefix + convertToPlainText(listItem, level + 1)
          })
          .join('')
        break

      case 'listItem':
        // Process list item content
        text += (node.content || []).map((child) => convertToPlainText(child, level)).join('')
        break

      case 'link':
        // Process link nodes (if any)
        const linkText = (node.content || []).map((child) => convertToPlainText(child, level)).join('')
        text += `[${linkText}] (${node.attrs.href})`
        break

      default:
        // For any other node types, recursively process their content
        if (node.content) {
          text += (node.content || []).map((child) => convertToPlainText(child, level)).join('')
        }
        break
    }

    return text
  }

  // Start conversion from the root node
  const plainText = convertToPlainText(jsonContent)

  // Trim the result and return
  return plainText.trim()
}

/**
 * Method to convert JSON Object OR JSON String OR String to plain text
 */
export const convertPossibleRichTextToPlainText = (message) => {
  // If the message is a string, it could be plain text or a JSON string
  if (typeof message === 'string') {
    try {
      // Attempt to parse the string as JSON
      const parsed = JSON.parse(message)

      // If parsing succeeds and the result is an object, process it
      if (typeof parsed === 'object' && parsed !== null) {
        return convertJsonToPlainText(parsed)
      } else {
        // If parsed JSON is not an object, return the original string
        return message
      }
    } catch (error) {
      // If parsing fails, assume it's plain text and return as is
      return message
    }
  }

  // If the message is an object, pass it directly to convertJsonToPlainText
  if (typeof message === 'object' && message !== null) {
    return convertJsonToPlainText(message)
  }

  // For other types (e.g., numbers, booleans), convert them to strings
  return String(message)
}

const processNodes = (nodes) => {
  return nodes
    .map((node) => {
      if (node.type === 'select') {
        return { type: 'text', text: node.attrs.selected || '' }
      } else if (node.type === 'date') {
        if (!node.attrs.date) return null
        const date = dayjs(node.attrs.date)
        if (!date.isValid()) return null
        return { type: 'text', text: date.format('LL') }
      } else if (node.content) {
        return { ...node, content: processNodes(node.content) }
      } else {
        return node
      }
    })
    .filter(Boolean)
}

export const processRichTextJsonToMessage = (json) => {
  const data = { ...json, content: processNodes(json.content) }
  return JSON.stringify(data)
}
