import useQuery from '@shared/hooks/src/useQuery'
import API from '@shared/services/src/API'
import { QK } from '@shared/utils'

export default function useInsuranceSummary(patientId, options = {}) {
  return useQuery({
    queryKey: QK.patients.id(patientId).insurances.summary,
    queryFn: () => API.patients.id(patientId).insurances.summary(),
    enabled: Boolean(patientId),
    ...options,
  })
}
