import { useEffect, useMemo, useRef, useState } from 'react'
import toast from 'react-hot-toast'
import dayjs from 'dayjs'
import { useLocalStorage } from 'usehooks-ts'

import { FeatureFlag, useFeatureFlag } from '@shared/providers/src/FeatureFlagsProvider'
import { useMe } from '@shared/providers/src/MeProvider'
import { mapRailsTimezoneToJS, UserRole } from '@shared/utils'

import AppointmentDetailsModal from '@pages/Appointments/AppointmentDetailsModal'
import { Fade, Stack, Typography } from '@mui-components'
import CalendarDate from '@components/CalendarDate'
import LinearProgress from '@components/LinearProgress'

import AppointmentForm from './AppointmentForm'
import AvailabilityForm from './AvailabilityForm'
import { FullCalendar, IndicatorGuide, ModeToggleGroup, ScheduleTypeToggleGroup, Timing } from './Calendar.components'
import { useCalendarData } from './Calendar.hooks'
import { CalendarEvent, findAvailability, LessThanThresholdErrorString, Mode, PastEntriesErrorString, ScheduleType } from './Calendar.utils'

export default function Calendar({
  provider,
  isLoading: isLoadingFromOutside = false,
  readOnly = false,
  date,
  setDate,
  eventId,
  setEventId,
}) {
  const me = useMe()
  const ref = useRef(null)
  const calendarRef = useRef(null)
  const availabilityAuthorizationEnabled = useFeatureFlag(FeatureFlag.AvailabilityAuthorization)

  const providerId = provider?.id
  const timezone = provider?.timezone
  const isNotAnAdmin = me.role !== UserRole.Admin

  const [view, setView] = useLocalStorage('scheduling-view', 'week') // day or week
  const [mode, setMode] = useLocalStorage('scheduling-mode', Mode.Availability)
  const [scheduleType, setScheduleType] = useLocalStorage('scheduling-type', ScheduleType.All)

  const [popoverPosition, setPopoverPosition] = useState(null)

  const [selectedAvailability, setSelectedAvailability] = useState(null)
  const [isUpdateAvailabilityOpen, setIsUpdateAvailabilityOpen] = useState(false)

  const [selectedAdHocAppointment, setSelectedAdHocAppointment] = useState(null)
  const [isAppointmentFormOpen, setIsAppointmentFormOpen] = useState(false)

  const [selectedAppointmentId, setSelectedAppointmentId] = useState(null)
  const [isAppointmentDetailsOpen, setIsAppointmentDetailsOpen] = useState(false)

  const { events, isLoading, businessHours, completedEncounters, availabilities, adminTimes } = useCalendarData({
    provider,
    date,
    scheduleType,
  })

  const handleSelect = ({ start, end, jsEvent }) => {
    const setSelectedDate = mode === Mode.Availability ? setSelectedAvailability : setSelectedAdHocAppointment
    const setFormOpen = mode === Mode.Availability ? setIsUpdateAvailabilityOpen : setIsAppointmentFormOpen

    setPopoverPosition({ top: jsEvent.clientY, left: jsEvent.clientX })
    setSelectedDate({ start, end })
    setFormOpen(true)
  }

  const handleClick = ({ event, jsEvent }) => {
    const id = event.extendedProps?.id
    const type = event.extendedProps?.type

    // Allow appointments to be viewed independently of the mode
    if (type === CalendarEvent.Appointment) {
      setSelectedAppointmentId(id)
      setIsAppointmentDetailsOpen(true)
      return
    }

    if (type === CalendarEvent.AdHocAppointment) {
      setPopoverPosition({ top: jsEvent.clientY, left: jsEvent.clientX })
      setSelectedAdHocAppointment({ id, start: event.start, end: event.end })
      setIsAppointmentFormOpen(true)
      return
    }

    // Prevent editing if in read-only mode
    if (readOnly) return

    // Prevent editing past entries
    const now = dayjs().tz(timezone)
    const isInThePast = dayjs(event.end).isBefore(now)
    if (isInThePast) return toast(PastEntriesErrorString)

    if (availabilityAuthorizationEnabled && isNotAnAdmin && type === CalendarEvent.Availability && event.extendedProps.approved) {
      const isLessThan72HoursUntil = dayjs(event.start).diff(now, 'hours') < 72
      if (isLessThan72HoursUntil) return toast(LessThanThresholdErrorString)
    }

    // Allow editing of availability and admin time in availability mode
    if ([CalendarEvent.Availability, CalendarEvent.AdminTime].includes(type)) {
      setPopoverPosition({ top: jsEvent.clientY, left: jsEvent.clientX })
      setSelectedAvailability({ id, type, start: event.start, end: event.end, approved: event.extendedProps.approved })
      setIsUpdateAvailabilityOpen(true)
      return
    }
  }

  const availabilityEvents = useMemo(() => {
    return events.filter((event) => event.extendedProps.type === CalendarEvent.Availability)
  }, [events])

  const selectOverlap = (event) => {
    if (mode === Mode.Appointment) {
      const { type, approved } = event.extendedProps
      return type === 'availability' && approved
    }
    return false
  }

  const selectAllow = ({ start, end }) => {
    if (readOnly) return false

    // Prevent editing past entries
    const isInThePast = dayjs(end).isBefore(dayjs().tz(timezone))
    if (isInThePast) return false

    if (mode === Mode.Availability) return true

    if (mode === Mode.Appointment) {
      const hasAvailability = findAvailability({ start, end }, availabilityEvents)
      return Boolean(hasAvailability)
    }
  }

  useEffect(() => {
    if (!eventId || !ref.current) return

    if (events.find((e) => e.id === eventId)) {
      // TODO Replace timeout with a better solution
      setTimeout(() => {
        ref.current?.highlightEvent(eventId)
      }, 1500)
      setEventId(undefined)
    }
  }, [eventId, events, setEventId])

  return (
    <Fade in>
      <Stack spacing={1} flexGrow={1}>
        <AvailabilityForm
          providerId={providerId}
          availability={selectedAvailability}
          open={isUpdateAvailabilityOpen}
          anchorPosition={popoverPosition}
          onClose={() => setIsUpdateAvailabilityOpen(false)}
        />
        <AppointmentForm
          readOnly={readOnly}
          providerId={providerId}
          timezone={timezone}
          appointment={selectedAdHocAppointment}
          availabilities={availabilityEvents}
          open={isAppointmentFormOpen}
          anchorPosition={popoverPosition}
          onClose={() => setIsAppointmentFormOpen(false)}
        />
        <AppointmentDetailsModal
          timezone={timezone}
          appointmentId={selectedAppointmentId}
          open={isAppointmentDetailsOpen}
          onClose={() => setIsAppointmentDetailsOpen(false)}
        />

        <Stack spacing={2}>
          <Stack direction="row" spacing={1} alignItems="flex-end" justifyContent="space-between">
            <Stack>
              <IndicatorGuide />
              <Typography variant="body2">
                Times shown in <b>Provider’s timezone {timezone ? `(${dayjs().tz(mapRailsTimezoneToJS(timezone)).format('z')})` : ''}</b>
              </Typography>
            </Stack>
            <Timing provider={provider} completedEncounters={completedEncounters} availabilities={availabilities} adminTimes={adminTimes} />
          </Stack>

          <Stack direction="row" spacing={4}>
            <ScheduleTypeToggleGroup value={scheduleType} onChange={setScheduleType} />
            {!readOnly && <ModeToggleGroup value={mode} onChange={setMode} />}
          </Stack>

          <CalendarDate
            date={date}
            onDateChange={setDate}
            view={view}
            onViewChange={(newView) => {
              setView(newView)
              calendarRef.current?.getApi()?.changeView(newView === 'day' ? 'timeGridDay' : 'timeGridWeek')
            }}
          />
        </Stack>

        <Stack sx={{ position: 'relative' }}>
          <LinearProgress loading={isLoading || isLoadingFromOutside} />
          <FullCalendar
            ref={ref}
            calendarRef={calendarRef}
            readOnly={readOnly}
            date={date}
            initialView={view === 'day' ? 'timeGridDay' : 'timeGridWeek'}
            events={events}
            businessHours={businessHours}
            selectAllow={selectAllow}
            selectOverlap={selectOverlap}
            onSelect={handleSelect}
            onClick={handleClick}
          />
        </Stack>
      </Stack>
    </Fade>
  )
}
