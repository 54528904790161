import useQuery from '@shared/hooks/src/useQuery'
import API from '@shared/services/src/API'
import { QK } from '@shared/utils'

export function usePlatformNotification(options = {}) {
  const query = { limit: 1, active: true, order: 'desc' }

  return useQuery({
    queryKey: QK.platform.notifications.list(query),
    queryFn: () => API.platform.notifications.list(query),
    select: (data) => data?.[0],
    ...options,
  })
}
