import { useLayoutEffect, useMemo } from 'react'
import { useInfiniteQuery } from '@tanstack/react-query'
import { useReadLocalStorage } from 'usehooks-ts'

import useQuery from '@shared/hooks/src/useQuery'
import { useMe } from '@shared/providers/src/MeProvider'
import API from '@shared/services/src/API'
import { AppointmentStatus, BuildEnv, flatten, pageParam, QK } from '@shared/utils'

export const FORMAT = 'YYYY-MM-DD HH:mm'
export const LIMIT = 50
const isProd = import.meta.env.VITE_BUILD_ENV === BuildEnv.Production

export function useMonthAvailability({ month, year }) {
  const me = useMe()
  const id = me.provider.id
  const query = { month, year }

  return useQuery({
    queryKey: QK.providers.id(id).availabilities.months.list(query),
    queryFn: () => API.providers.id(id).availabilities.monthly(query),
  })
}

// Loads by LIMIT chunks until there are no more items
export function useDateAvailability(date) {
  const overriddenLimit = useReadLocalStorage('override-page-size') || LIMIT
  const limit = isProd ? LIMIT : overriddenLimit

  const me = useMe()
  const id = me.provider.id

  const query = { limit, start_date: date.startOf('day').format(FORMAT), end_date: date.endOf('day').format(FORMAT) }

  const queryResult = useInfiniteQuery({
    queryKey: QK.providers.id(id).availabilities.list(query),
    queryFn: ({ pageParam }) => API.providers.id(id).availabilities.list({ ...query, offset: pageParam * limit }),
    select: flatten,
    initialPageParam: 0,
    getNextPageParam: pageParam(limit),
  })

  const { fetchNextPage, hasNextPage, isFetchingNextPage } = queryResult

  useLayoutEffect(() => {
    if (!isFetchingNextPage && hasNextPage) {
      fetchNextPage()
    }
  }, [fetchNextPage, hasNextPage, isFetchingNextPage])

  return queryResult
}

export function useAppointments(providerId, dateRange, { start, end }) {
  const query = useMemo(() => {
    const [startDate, endDate] = dateRange
    return {
      start_date: `${startDate?.format('YYYY-MM-DD')}T${start.format('HH:mm')}`,
      end_date: `${endDate?.format('YYYY-MM-DD')}T${end.format('HH:mm')}`,
      appointment_status: AppointmentStatus.Scheduled,
    }
  }, [dateRange, start, end])

  return useQuery({
    queryKey: QK.providers.id(providerId).appointments.list(query),
    queryFn: () => API.providers.id(providerId).appointments.list(query),
  })
}
