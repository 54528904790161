import { ErrorBoundary } from 'react-error-boundary'
import { Outlet } from 'react-router-dom'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import QS from 'query-string'
import { QueryParamProvider } from 'use-query-params'
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6'

import QueryClientProvider from '@shared/providers/src/QueryClientProvider'
import { BuildEnv } from '@shared/utils'

import AxiosErrorHandler from '@providers/AxiosErrorHandler'
import LocalesProvider from '@providers/LocalesProvider'
import LoginHandler from '@providers/LoginHandler'

import ThemeProvider from '../../themes'
import Error from '../Misc/Error'

export default function Root() {
  return (
    <QueryParamProvider adapter={ReactRouter6Adapter} options={{ searchStringToObject: QS.parse, objectToSearchString: QS.stringify }}>
      <ThemeProvider>
        <LocalesProvider>
          <ErrorBoundary FallbackComponent={Error}>
            <AxiosErrorHandler>
              <QueryClientProvider>
                <Outlet />
                <LoginHandler />
                {import.meta.env.VITE_BUILD_ENV !== BuildEnv.Production && <ReactQueryDevtools initialIsOpen={false} />}
              </QueryClientProvider>
            </AxiosErrorHandler>
          </ErrorBoundary>
        </LocalesProvider>
      </ThemeProvider>
    </QueryParamProvider>
  )
}
