import { useCallback } from 'react'
import { useMutation } from '@tanstack/react-query'

import { useMe } from '@shared/providers/src/MeProvider'
import { queryClient } from '@shared/providers/src/QueryClientProvider'
import API from '@shared/services/src/API'
import { QK } from '@shared/utils'

export function useAvailabilityCreate() {
  const me = useMe()
  const providerId = me.provider.id

  const create = useMutation({
    mutationFn: (data) => API.providers.id(providerId).availabilities.createRange(data),
  })

  return useCallback(
    (data) => {
      return create.mutateAsync(data).then(() => {
        queryClient.invalidateQueries({ queryKey: QK.providers.id(providerId).availabilities.months.lists })
        queryClient.invalidateQueries({ queryKey: QK.providers.id(providerId).availabilities.lists })
      })
    },
    [create, providerId]
  )
}
