import { useEffect, useRef } from 'react'
import { defaults } from 'lodash'

import { Logger, PubNub } from '@shared/utils'

const log = Logger('usePubNub.js')

/**
 * Helper hook to reduce boilerplate when subscribing to PubNub events.
 *
 * @example
 * usePubNub(
 *   'some-event,
 *   (data) => console.log(data)
 *   { enabled: Boolean(conversationId) }
 * )
 */
export default function usePubNub(channel, listener, options = {}) {
  const subscriber = useRef(listener)

  const { enabled } = defaults(options, {
    enabled: true,
  })

  useEffect(() => {
    subscriber.current = listener
  }, [listener])

  useEffect(() => {
    if (!enabled) return

    const id = PubNub.on(channel, subscriber.current)
    log.debug('[SUBSCRIBED]', id, channel)
    return () => {
      PubNub.off(id, channel)
      log.debug('[UNSUBSCRIBED]', id, channel)
    }
  }, [channel, enabled])
}

export const Channels = {
  PatientJoined: 'patient_joined',
  ProviderJoined: 'provider_joined',
  VideoCompleted: 'completed_video',
  NewMessage: 'new_message',
  UnreviewedLabs: 'unreviewed_labs',
  HHAlerts: 'update_health_history_alerts',
  CallStatusChanged: 'call_status_changed',
  UpdateQuarterlyFlowCount: 'update_quarterly_flow_count',
  ReassignedPatient: 'reassigned_patient',
  AppointmentStatusChanged: 'status_changed',
  EmailVerified: 'email_verified',
}
