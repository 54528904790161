import { useMutation } from '@tanstack/react-query'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import { queryClient } from '@shared/providers/src/QueryClientProvider'
import API from '@shared/services/src/API'
import { BreakGlassType, QK } from '@shared/utils'

function useUnlock({ patientId, onSuccess }) {
  return useMutation({
    mutationFn: (data) => API.patients.id(patientId).breakGlass.unlock(data),
    onSuccess: onSuccess || (() => queryClient.refetchQueries({ queryKey: QK.patients.id(patientId).details })),
  })
}

export function useForm({ patientId, onSuccess }) {
  const unlock = useUnlock({ patientId, onSuccess })

  return useFormik({
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: { password: '' },
    validationSchema: Yup.object().shape({
      password: Yup.string().required('Password is required'),
    }),
    onSubmit: ({ password }, { setFieldError }) => {
      return unlock.mutateAsync({ password }).catch(() => setFieldError('password', 'No match was found'))
    },
  })
}

/**
 * HOC function to check if the user must break glass to access the patient.
 */
export function withBreakGlassCheck(Component) {
  return function BreakGlassCheck({ patient, ...props }) {
    if (isBreakGlassRequired(patient)) {
      return <Component {...props} patientId={patient?.id} />
    }

    return props.children
  }
}

const isBreakGlassRequired = (patient) => patient?.breakGlass === BreakGlassType.Required
