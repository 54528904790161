import { Box } from '@mui/material'

import { useMe } from '@shared/providers/src/MeProvider'
import { BuildEnv, toTitleCase } from '@shared/utils'

import { Typography } from '@mui-components'

const envToColor = {
  [BuildEnv.Staging]: 'success',
  [BuildEnv.QA]: 'warning',
  [BuildEnv.Local]: 'info',
}

const envToLabel = {
  [BuildEnv.Staging]: 'Staging',
  [BuildEnv.QA]: 'QA',
  [BuildEnv.Local]: 'Local',
}

const styles = {
  env: (color) => ({
    backgroundColor: `${color}.lighter`,
    ml: 0.5,
    py: 0.5,
    px: 1,
    borderRadius: 1,
  }),
}

export default function Environment() {
  const env = import.meta.env.VITE_BUILD_ENV
  const me = useMe()

  if (env === BuildEnv.Production) return null

  const color = envToColor[env] || 'info'

  return (
    <Box sx={styles.env(color)}>
      <Typography noWrap variant="h5" component="span" color={`${color}.dark`} fontWeight="normal">
        {envToLabel[env] || env} - {toTitleCase(me.role)}
      </Typography>
    </Box>
  )
}
