import useQuery from '@shared/hooks/src/useQuery'
import { useQueryEvents } from '@shared/hooks/src/useQueryEvents'
import API from '@shared/services/src/API'
import { QK } from '@shared/utils'

import UsersApi from '@services/Users.api'

export function useUserCard(userId) {
  const query = useQuery({
    queryKey: QK.users.id(userId).card,
    queryFn: () => UsersApi.userCard(userId, { skipHandling: true }),
    enabled: Boolean(userId),
  })

  useQueryEvents(query, {
    onSuccess: () => {
      // This is a hack to force the window to resize so that the user card can reposition itself
      // Useful when Popper is near the edge of the screen
      window.dispatchEvent(new Event('resize'))
    },
  })

  return query
}

export function useProviderCBOs(providerId, state, options = {}) {
  return useQuery({
    queryKey: QK.providers.id(providerId).cbos.list({ state }),
    queryFn: () => API.providers.id(providerId).cbos.list({ state }, { skipHandling: true }),
    ...options,
  })
}
