import { useMutation } from '@tanstack/react-query'

import useQuery from '@shared/hooks/src/useQuery'
import { queryClient } from '@shared/providers/src/QueryClientProvider'
import API from '@shared/services/src/API'
import { mapCache, QK } from '@shared/utils'

export function useCBO(cboId, options = {}, config) {
  return useQuery({
    queryKey: QK.cbos.id(cboId).details,
    queryFn: () => API.cbos.id(cboId).details(undefined, config),
    ...options,
  })
}

export function useCBOStatusUpdate() {
  return useMutation({
    mutationFn: ({ id, status }) => API.admin.cbos.id(id).updateStatus({ status }),
    onSuccess: (response, { id, status }) => {
      return queryClient.setQueriesData(
        { queryKey: QK.admin.cbos.lists },
        mapCache((old) => (old.id === id ? { ...old, data: status } : old))
      )
    },
  })
}

export function useCBOUpdate(cboId) {
  return useMutation({
    mutationFn: (data) => API.cbos.id(cboId).update(data),
    onSuccess: (data) => queryClient.setQueryData(QK.cbos.id(cboId).details, data),
  })
}

export function useCBOContractedPharmacies(cboId, query, options = {}) {
  return useQuery({
    queryKey: QK.cbos.id(cboId).contractedPharmacies.list(query),
    queryFn: () => API.cbos.id(cboId).pharmacies.contracted.list(query),
    ...options,
  })
}
