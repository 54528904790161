import { useMemo } from 'react'
import ModalProvider from 'mui-modal-provider'
import PropTypes from 'prop-types'
import { createTheme, ThemeProvider as ThemeProviderMUI } from '@mui/material/styles'
import { LocalizationProvider } from '@mui/x-date-pickers-pro'
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs'

import { CssBaseline, StyledEngineProvider } from '@mui-components'
import Toaster from '@components/Toast'

import componentsOverride from './overrides'
import Palette from './palette'
import CustomShadows from './shadows'
import Typography from './typography'

export default function ThemeProvider({ children }) {
  const theme = useMemo(() => Palette('light'), [])
  const themeCustomShadows = useMemo(() => CustomShadows(theme), [theme])

  const themeOptions = useMemo(
    () => ({
      breakpoints: {
        values: {
          xs: 0,
          sm: 768,
          md: 1024,
          lg: 1266,
          xl: 1536,
          xxl: 1920,
        },
      },
      mixins: {
        sidebar: {
          minifiedWidth: 60,
          fullWidth: 260,
        },
        toolbar: {
          height: 59,
          minHeight: 59,
          paddingTop: 8,
          paddingBottom: 8,
        },
        footer: {
          height: 35,
        },
      },
      palette: theme.palette,
      customShadows: themeCustomShadows,
      typography: Typography,
      twilio: {
        videoWidth: 360,
        headerHeight: 42,
        footerHeight: 72,
        sidebarWidth: 300,
        sidebarMobileHeight: 90,
        sidebarMobilePadding: 8,
        participantBorderWidth: 2,
      },
    }),
    [theme, themeCustomShadows]
  )

  const themeOverride = createTheme(themeOptions)
  themeOverride.components = componentsOverride(themeOverride)

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <StyledEngineProvider injectFirst>
        <ThemeProviderMUI theme={themeOverride}>
          <CssBaseline />
          <Toaster />
          <ModalProvider>{children}</ModalProvider>
        </ThemeProviderMUI>
      </StyledEngineProvider>
    </LocalizationProvider>
  )
}

ThemeProvider.propTypes = {
  children: PropTypes.node,
}
