import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

export const AuthBroadcastChannel = new BroadcastChannel('auth-channel')

export const AuthBroadcastTypes = {
  LoginSuccess: 'LOGIN_SUCCESS',
}

export default function LoginHandler() {
  const navigate = useNavigate()

  useEffect(() => {
    const handleMessage = (event) => {
      const { type, source } = event.data

      if (type === AuthBroadcastTypes.LoginSuccess && source !== window.name) {
        navigate('/app', { replace: true })
      }
    }

    window.name = window.name || Math.random().toString(36).substring(2)

    AuthBroadcastChannel.addEventListener('message', handleMessage)

    return () => {
      AuthBroadcastChannel.removeEventListener('message', handleMessage)
      AuthBroadcastChannel.close()
    }
  }, [navigate])

  return null
}
