import dayjs from 'dayjs'
import { createEnumParam, StringParam, useQueryParam, withDefault } from 'use-query-params'

import { FeatureFlag, useFeatureFlag } from '@shared/providers/src/FeatureFlagsProvider'
import { isUserAllowed, useMe } from '@shared/providers/src/MeProvider'
import { UserRole } from '@shared/utils'

const ViewParam = createEnumParam(['day', 'week'])

export function useTab() {
  const managementAllowed = useManagementAllowed()
  const gapsAndAvailabilityAllowed = useGapsAndAvailabilityAllowed()

  const tabs = ['scheduling']
  if (managementAllowed) tabs.push('management')
  if (gapsAndAvailabilityAllowed) tabs.push('gaps', 'availability')

  const TabParam = createEnumParam(tabs)
  return useQueryParam('tab', withDefault(TabParam, 'scheduling'), { updateType: 'replaceIn' })
}

export function useDate() {
  return useQueryParam('date', withDefault(StringParam, dayjs().format('YYYY-MM-DD')), { updateType: 'replaceIn' })
}

export function useView() {
  return useQueryParam('view', withDefault(ViewParam, 'day'), { updateType: 'replaceIn' })
}

export function useGapsAndAvailabilityAllowed() {
  const me = useMe()
  return isUserAllowed(me, [], [UserRole.Support, UserRole.MA])
}

export function useManagementAllowed() {
  const me = useMe()
  const availabilityAuthorizationEnabled = useFeatureFlag(FeatureFlag.AvailabilityAuthorization)
  return isUserAllowed(me, [UserRole.Admin]) && availabilityAuthorizationEnabled
}
