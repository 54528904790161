import { useState } from 'react'
import PropTypes from 'prop-types'
import { useEventListener } from 'usehooks-ts'

import { useLayoutState } from '@providers/LayoutStateProvider'
import { Box, Skeleton, Stack, Typography } from '@mui-components'
import Breadcrumbs from '@components/Breadcrumbs'
import DocTitle from '@components/DocTitle'
import SearchInput from '@components/SearchInput'

PageHeader.propTypes = {
  /** Set the document title to the title of the page */
  docTitle: PropTypes.bool,

  /** Title of the page */
  title: PropTypes.string.isRequired,

  /** Subtitle of the page */
  subtitle: PropTypes.string,

  /** Size of the title */
  size: PropTypes.oneOf(['small', 'medium', 'large']),

  /** Indexes of the breadcrumbs that should not be links */
  hideLinksByIndex: PropTypes.array,

  /** Action to be displayed on the right side of the header */
  action: PropTypes.node,

  /** Props for the search input */
  searchProps: PropTypes.object,

  /** Can display a loading indicator */
  loading: PropTypes.bool,
}

const styles = {
  container: (isScrolled, sidebarOpen) => (theme) => {
    const sidebarWidth = sidebarOpen ? theme.mixins.sidebar.fullWidth : theme.mixins.sidebar.minifiedWidth

    return {
      position: 'fixed',
      top: `${theme.mixins.toolbar.height}px`,
      left: 0,
      right: 0,
      width: '100%',
      [theme.breakpoints.up('lg')]: {
        left: sidebarWidth,
        right: 0,
        width: `calc(100% - ${sidebarWidth}px)`,
      },
      justifyContent: 'space-between',
      alignItems: 'center',
      overflow: 'hidden',
      px: {
        xs: 1,
        sm: 2,
        md: 4,
      },
      backgroundImage: 'url("/waves/primary/bottom.svg"), url("/waves/primary/top.svg")',
      backgroundPosition: 'bottom left, top right',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '25%, 35%',
      backgroundColor: 'primary.lighter',
      boxShadow: isScrolled ? theme.customShadows.z2 : theme.customShadows.z1,
      height: isScrolled ? 60 : 100,
      transition: 'height 0.3s ease, box-shadow 0.3s ease',
      zIndex: 1000,
    }
  },
  placeholder: {
    height: 100,
  },
  search: {
    maxWidth: 260,
    backgroundColor: 'common.white',
    '& .MuiInputBase-input': { py: 1 },
  },
  right: {
    flexGrow: 1,
    flexShrink: 0,
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
}

/**
 * Header of page
 *
 * @example
 * <PageHeader
 *   title="Themes"
 *   subtitle="Color and Images applied to the patient app to personalize the experience."
 *   searchProps={{ label: 'Search', value: '', onChange: () => {} }}
 * />
 */
export default function PageHeader({
  docTitle = false,
  title,
  subtitle,
  action,
  searchProps,
  size = 'medium',
  hideLinksByIndex = [],
  loading = false,
}) {
  const [isScrolled, setIsScrolled] = useState(false)
  const [sidebarOpen] = useLayoutState()

  useEventListener('scroll', () => {
    const scrollTop = window.scrollY
    setIsScrolled(scrollTop > 50)
  })

  const content = (
    <Typography variant="h3" fontWeight="medium">
      {loading && !title ? <Skeleton width={200} /> : title}
    </Typography>
  )

  return (
    <Box sx={styles.placeholder}>
      <Stack direction="row" spacing={2} sx={[styles.container(isScrolled, sidebarOpen), styles[size]]}>
        {docTitle && <DocTitle title={title} />}
        <Stack justifyContent="center">
          <Stack direction="row" alignItems="center">
            <Breadcrumbs content={content} hideLinksByIndex={hideLinksByIndex} />
          </Stack>
          {subtitle && <Typography>{subtitle}</Typography>}
        </Stack>
        <Stack direction="row" spacing={2} sx={styles.right}>
          {action}
          {searchProps && <SearchInput sx={styles.search} {...searchProps} />}
        </Stack>
      </Stack>
    </Box>
  )
}
