import { useState } from 'react'
import dayjs from 'dayjs'
import { useFormik } from 'formik'
import { useLocalStorage } from 'usehooks-ts'

import usePromiseLoading from '@shared/hooks/src/usePromiseLoading'
import { FeatureFlag, useFeatureFlag } from '@shared/providers/src/FeatureFlagsProvider'
import { isUserAllowed, useMe } from '@shared/providers/src/MeProvider'
import { UserRole } from '@shared/utils'

import { Divider, Popover, Stack } from '@mui-components'
import LinearProgress from '@components/LinearProgress'

import { AppointmentNotice } from '../../Availability.utils'
import { validationSchema } from '../../AvailableItems'
import {
  useAdminTimeCreate,
  useAdminTimeEdit,
  useAdminTimeRemove,
  useAvailabilityApprove,
  useAvailabilityCreate,
  useAvailabilityDecline,
  useAvailabilityEdit,
  useAvailabilityRemove,
} from '../../Edit/Edit.hooks'
import {
  ActionButtons,
  ApprovalButtons,
  AvailabilityToggleGroup,
  handleRemoveErrors,
  handleSubmitErrors,
  TimeRangePicker,
} from './AvailabilityForm.utils'

export default function AvailabilityUpdate({ providerId, availability, open, onClose, anchorPosition }) {
  return (
    <Popover
      id={open ? 'availability-popover' : undefined}
      open={open}
      anchorReference={anchorPosition ? 'anchorPosition' : 'none'}
      anchorPosition={anchorPosition}
      disableAutoFocus
      disableRestoreFocus
      onClose={onClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      transformOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      <AvailabilityForm providerId={providerId} availability={availability} onClose={onClose} />
    </Popover>
  )
}

function AvailabilityForm({ providerId, availability, onClose }) {
  const [isNotice, setIsNotice] = useState(false)
  const [type, setType] = useLocalStorage('last-used-availability-type', 'availability')

  const me = useMe()
  const availabilityAuthorizationEnabled = useFeatureFlag(FeatureFlag.AvailabilityAuthorization)

  const isEditing = Boolean(availability?.id)
  const canAuthorize =
    isEditing &&
    availability.type === 'availability' &&
    !availability.approved &&
    isUserAllowed(me, [UserRole.Admin]) &&
    availabilityAuthorizationEnabled

  const createAvailability = useAvailabilityCreate(providerId)
  const editAvailability = useAvailabilityEdit(providerId)
  const removeAvailability = useAvailabilityRemove(providerId)
  const approveAvailability = useAvailabilityApprove()
  const declineAvailability = useAvailabilityDecline()

  const createAdminTime = useAdminTimeCreate(providerId)
  const editAdminTime = useAdminTimeEdit(providerId)
  const removeAdminTime = useAdminTimeRemove(providerId)

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    initialValues: {
      id: availability?.id ?? null,
      start: availability?.start ? dayjs(availability.start) : null,
      end: availability?.end ? dayjs(availability.end) : null,
    },
    validationSchema,
    onSubmit: ({ id, start, end }, { setFieldError }) => {
      return update({
        id,
        type: availability?.type || type,
        start: start.format('HH:mm'),
        end: end.format('HH:mm'),
      }).catch((e) => handleSubmitErrors(e, setFieldError, isEditing, formik.resetForm, setIsNotice))
    },
  })

  const update = ({ type, start, end }) => {
    let mutation
    if (type === 'availability') mutation = isEditing ? editAvailability : createAvailability
    if (type === 'adminTime') mutation = isEditing ? editAdminTime : createAdminTime

    return mutation({
      id: availability.id,
      date_formatted: dayjs(availability.start).format('YYYY-MM-DD'),
      start_time_formatted: start,
      end_time_formatted: end,
    }).then(() => {
      // don't close the form if the availability is not approved
      // cause after editing user might want to decline/approve it
      if (canAuthorize) return
      return onClose()
    })
  }

  const [remove, removing] = usePromiseLoading(() => {
    let mutation
    if (availability.type === 'availability') mutation = removeAvailability
    if (availability.type === 'adminTime') mutation = removeAdminTime

    return mutation(availability.id)
      .then(onClose)
      .catch((e) => handleRemoveErrors(e, setIsNotice))
  })

  const isLoading = formik.isSubmitting || removing

  return (
    <form noValidate onSubmit={formik.handleSubmit}>
      <AppointmentNotice
        providerId={providerId}
        dateRange={[dayjs(availability?.start), dayjs(availability?.end)]}
        time={formik.values}
        open={isNotice}
        onClose={() => setIsNotice(false)}
      />
      <LinearProgress loading={isLoading} />

      <Stack p={2} spacing={2}>
        <AvailabilityToggleGroup value={isEditing ? availability.type : type} onChange={setType} disabled={isEditing} />

        <TimeRangePicker
          start={formik.values.start}
          end={formik.values.end}
          onStartChange={(start) => formik.setFieldValue('start', start)}
          onEndChange={(end) => formik.setFieldValue('end', end)}
          errors={formik.errors}
          touched={formik.touched}
          isLoading={isLoading}
        />

        <ActionButtons isLoading={isLoading} isEditing={isEditing} onCancel={onClose} isRemoving={removing} onDelete={remove} />
        {canAuthorize && (
          <>
            <Divider />
            <ApprovalButtons
              disabled={isLoading}
              onDecline={() => declineAvailability(providerId, availability.id).then(onClose)}
              onApprove={() => approveAvailability(providerId, availability.id).then(onClose)}
            />
          </>
        )}
      </Stack>
    </form>
  )
}
