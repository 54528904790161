import { getTestId, userRoleToLabel } from '@shared/utils'

import { Skeleton, Stack, Typography } from '@mui-components'
import Avatar from '@components/Avatar'

export default function User({ small = false, user, children, ...rest }) {
  if (!user) return null

  const testId = getTestId(rest, `user-${user.fullName}`)

  return (
    <Stack direction="row" spacing={1} alignItems="center" data-testid={testId}>
      <Avatar user={user} size={small ? 'sm' : 'md'} hover="card" />
      <Stack spacing={-0.5}>
        <Typography variant={small ? 'body2' : 'body1'} data-testid={`${testId}-name`}>
          {user.fullName}
        </Typography>
        {children || (
          <Typography variant={small ? 'body2' : 'body1'} color="text.secondary" data-testid={`${testId}-subtitle`}>
            {userRoleToLabel[user.role]}
          </Typography>
        )}
      </Stack>
    </Stack>
  )
}

User.Loading = function ({ small }) {
  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <Skeleton variant="rounded" width={small ? 32 : 40} height={small ? 32 : 40} />
      <Stack spacing={-0.5}>
        <Typography variant={small ? 'body2' : 'body1'}>
          <Skeleton width={150} />
        </Typography>
        <Typography variant={small ? 'body2' : 'body1'}>
          <Skeleton width={120} />
        </Typography>
      </Stack>
    </Stack>
  )
}
