import useQuery from '@shared/hooks/src/useQuery'
import API from '@shared/services/src/API'
import { QK } from '@shared/utils'

export default function useBusinessHours() {
  return useQuery({
    queryKey: QK.platform.businessHours,
    queryFn: () => API.platform.businessHours(),
  })
}
