import { deserialize } from 'deserialize-json-api'
import QS from 'query-string'

import api from '@shared/services'

const UsersApi = {
  me: (config) => api.get('/me', config).then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data),
  user: (userId, config) => api.get(`/users/${userId}`, config).then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data),
  users: (query, config) =>
    api
      .get(QS.stringifyUrl({ url: '/users', query }), config)
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? []),
}

export default UsersApi
