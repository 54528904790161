import { keepPreviousData, useInfiniteQuery, useMutation } from '@tanstack/react-query'

import { queryClient } from '@shared/providers/src/QueryClientProvider'
import API from '@shared/services/src/API'
import { flatten, handleError, pageParam, QK } from '@shared/utils'

const NOTES_LIMIT = 5

export function useAdHocAppointmentNotes(providerId, appointmentId) {
  const query = { limit: NOTES_LIMIT }
  return useInfiniteQuery({
    queryKey: QK.appointments.id(appointmentId).notes.list(query),
    queryFn: ({ pageParam }) =>
      API.providers
        .id(providerId)
        .appointments.adHoc.id(appointmentId)
        .notes.list({ ...query, offset: pageParam * NOTES_LIMIT }),
    placeholderData: keepPreviousData,
    enabled: Boolean(appointmentId),
    select: flatten,
    initialPageParam: 0,
    getNextPageParam: pageParam(NOTES_LIMIT),
  })
}

export function useAdHocAppointmentNoteCreation(providerId, appointmentId) {
  return useMutation({
    mutationFn: (message) => API.providers.id(providerId).appointments.adHoc.id(appointmentId).notes.create({ message }),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: QK.appointments.id(appointmentId).notes.lists }),
    onError: handleError,
  })
}
